import React, { useState } from "react";

import { useList } from 'react-firebase-hooks/database';

import { Link } from 'react-router-dom';

import PropTypes from "prop-types";

import { database } from "../../../firebase";
import { ref } from 'firebase/database';

import { styled, useTheme } from '@mui/system';

import { useTranslation } from 'react-i18next';

import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ComputerIcon from '@mui/icons-material/Computer';
import PhoneIcon from '@mui/icons-material/PhoneAndroid';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { formatDateLong } from "../../helper/dateUtils";

import SearchBar from "../../SearchBar";

import { 
  IconButton,
  List,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  ListItemButton,
  Box,
  Typography
} from "@mui/material";

const LoadRootDiv = styled('div')(({theme}) => ({
  display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
}))

const MurcsCircularProgress = styled(CircularProgress)({
  margin: 'auto',
})

const RoomListItem = (props) => {
  const {data, roomKey} = props
  const { i18n } = useTranslation();
  
  const createdDate = new Date(data.created);
  const formattedDate = formatDateLong(createdDate, i18n.language);
  
  return (
    <ListItemButton component={Link} to={"/planning/".concat(roomKey)}>
      <ListItemAvatar>
          <Avatar>
            <ScheduleIcon color="secondary"/>
          </Avatar>
        </ListItemAvatar>
      <ListItemText 
        primary = {
        <Typography variant="body1" color="textPrimary">
          {data.name}
        </Typography>} 
        secondary={formattedDate} />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="comments" color="secondary" label="Board" component={Link} to={"/planning/".concat(roomKey)}>
          <ComputerIcon />
        </IconButton>
        <IconButton edge="end" aria-label="comments" color="secondary" label="Participate" component={Link} to={"/planning/".concat(roomKey).concat("/participate")}>
          <PhoneIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItemButton>
  )
}


const PlanningRoomList = (props) => {
  // const [rooms, setRooms] = React.useState([]);
  const [rooms, loading, error] = useList(ref(database,"planning/publicrooms"));
  const theme = useTheme();

  const [searchTerm, setSearchTerm] = useState("");
  const { t: translate } = useTranslation();

  const filteredRooms = rooms.filter(room => {
    return room.val().name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
  
    <> 
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="subtitle1" color="textPrimary" component="div" sx={{ fontWeight: 500, fontSize: '0.9rem', px: 2, py: 1 }}>
          {translate('planning.roomListTitle')}
        </Typography>
        <SearchBar onChange={setSearchTerm} />
      </Box>

      {error && <strong>{translate('planning.errorMessage')}</strong>}
      {loading && <LoadRootDiv ><MurcsCircularProgress /></LoadRootDiv>}

      <List>
        {typeof rooms !== 'undefined' && filteredRooms.map( room => (
          <RoomListItem key={room.key} roomKey={room.key} data={room.val()} />
          
        ))}
        
      </List>
    </>
     
  );
}


PlanningRoomList.propTypes = {
  user: PropTypes.object,
};

export default PlanningRoomList;
