import React from "react";

import PropTypes from "prop-types";

import { styled, useTheme } from '@mui/system';

import { useTranslation, Trans } from 'react-i18next';

import {
  Typography,
  Grid,
  Link
} from "@mui/material";

const RootContainerGrid = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  width: '100%',
  margin: 0
}))

const RoomTitleTypography = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  textAlign: "center"
}))


const ContactPage = (props) => {
  const { t: translate } = useTranslation();

const theme = useTheme();

  return (
    <RootContainerGrid container justifyContent="center" alignItems="center" spacing={3} >
      <Grid xs={12} item>
        <RoomTitleTypography variant="h2" sx={{ color: theme.palette.primary.light }}>{translate('contact.title')}</RoomTitleTypography>
      </Grid>
      <Grid xs={12} item textAlign="center">
        <Typography>
          {translate('contact.intro')}
        </Typography>
        <Typography variant="h5" sx={{ marginTop: 6, color: theme.palette.primary.veryLight }}>          
          {translate('contact.reachUs')}
        </Typography>
        <Typography sx={{ marginTop: 2 }}>            
          {translate('contact.contactDetails')}
        </Typography> 
        <Typography sx={{ marginTop: 1 }}>
          <Trans i18nKey="contact.joinSlack" components={{1: <Link href="https://check24.slack.com/archives/C077XQ7RQD9" underline="hover" sx={{ fontWeight: "bold", color: theme.palette.primary.veryLight }}>"c24-murcs-support"</Link>}} />
          {translate('contact.joinSlackPart2')}
        </Typography> 
        <Typography sx={{ marginTop: 10 }}> 
          {translate('contact.thanks')}
        </Typography>  
      </Grid>
    </RootContainerGrid>
  );
}



ContactPage.propTypes = {
  user: PropTypes.object
};

export default ContactPage;
