import React from "react";

import useFetch from 'use-http'

import PropTypes from "prop-types";

import { auth } from "../../firebase";

import { styled } from '@mui/system';

import { useTranslation } from 'react-i18next';

import {
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  useTheme
} from "@mui/material";
import md5 from "md5";

const RootContainerGrid = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  width: '100%',
  margin: 0
}))

const MurcsGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2)
}))

const LoadRootDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > * + *': {
    marginLeft: theme.spacing(2),
  },
}))

const MurcsCircularProgress = styled(CircularProgress)(({ theme }) => ({
  margin: 'auto',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2)
}))

const ErrorTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.primary.red
}))

const SendButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
}))

const RoomTitleTypography = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  textAlign: "center"
}))

const MurcsPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
}))


const PasswordScreen = (props) => {
  const { roomId, room, endpoint } = props;

  const roomVal = (typeof room !== 'undefined') ? room.val() : {}

  const [password, setPassword] = React.useState('')
  const [authenticated, setAuthenticated] = React.useState(null)

  const [request, response] = useFetch(process.env.REACT_APP_FUNCTIONS_URL)

  const { t: translate } = useTranslation();
  const theme = useTheme();


  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }


  const handleSubmit = (event) => {
    event.preventDefault();

    authenticateUser(password)
  }

  async function authenticateUser(password) {
    const body = {
      room: roomId,
      user: auth.currentUser.uid,
      password: md5(password),
    }

    setAuthenticated("pending")
    await request.post(endpoint, body)

    if (!response.ok) {
      setAuthenticated(response.status)
    }
  }

  const formValid = () => {
    return true
  }

  return (
    <RootContainerGrid container justifyContent="center" alignItems="center" spacing={3} >
      <Grid xs={12} item>
        <RoomTitleTypography variant="h2" sx={{ color: theme.palette.primary.light }} >{roomVal !== null && roomVal.name !== null ? roomVal.name : "Password needed"}</RoomTitleTypography>
      </Grid>
      <MurcsGrid item xs={12} sm={8} md={6}>

        <MurcsPaper>

          <Typography variant="body1">{translate('passwordScreen.description')}</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              id="standard-full-width"
              label={translate('passwordScreen.passwordTitle')}
              placeholder={translate('passwordScreen.passwordPlaceholder')}
              helperText=""
              fullWidth
              onChange={handlePasswordChange}
              type="password"
              margin="normal"
              sx={{ 
                '& label.Mui-focused': {
                  color: theme.palette.primary.veryLight
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.light
                  },
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <SendButton variant="contained" color="primary" type="submit" disabled={!formValid}>{translate('passwordScreen.joinButtonTitle')}</SendButton>
          </form>

          {authenticated === "pending" && <LoadRootDiv><MurcsCircularProgress /></LoadRootDiv>}
          {authenticated === 401 && <ErrorTypography variant="body1">{translate('passwordScreen.passwordNotCorrect')}</ErrorTypography>}
          {authenticated !== null && authenticated !== 401 && authenticated !== "pending" &&<ErrorTypography variant="body1">{translate('passwordScreen.unknownError')}</ErrorTypography>}

        </MurcsPaper>

      </MurcsGrid>
    </RootContainerGrid>
  )
}

PasswordScreen.propTypes = {
  user: PropTypes.object
};

export default PasswordScreen;
