import React from 'react';
import Changelog from '../changelog/Changelog';
import ChangelogEntries from './ChangelogEntries';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { 
    Grid,
    Typography,
    useTheme
} from '@mui/material';

const RootContainerGrid = styled(Grid)(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(2),
    width: '100%',
    margin: 0
}))

const RoomTitleTypography = styled(Typography)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    textAlign: "center"
}))

const ChangelogPage = () => {
    const theme = useTheme();
    const { t: translate } = useTranslation();
    return (
        <RootContainerGrid container justifyContent="center" alignItems="center" spacing={3} >
            <Grid xs={12} item>
                <RoomTitleTypography variant="h2" sx={{ color: theme.palette.primary.light }}>{translate('changelog.changelogTitle')}</RoomTitleTypography>
            </Grid>
            <Grid xs={12} item>
                <Changelog entries={ChangelogEntries} />
            </Grid>
        </RootContainerGrid>
    );
};

export default ChangelogPage;
