export const mapPlanningValue = (value, roomValues) => {

    const customValue = roomValues.find(v => v.id === value); // überprüfen, ob der Wert in roomValues vorhanden und ein benutzerdefinierter Wert ist
    if (customValue) return customValue.label; // direkte Rückgabe des Labels, wenn es sich um einen benutzerdefinierten Wert handelt

}

export const sortPlanningValue = (v1, v2) => {

        const num1 = parseFloat(v1.label);
        const num2 = parseFloat(v2.label);
        
        return num1 - num2;
      
}
