import React, { useState } from "react";

import { useList } from 'react-firebase-hooks/database';

import { Link } from 'react-router-dom';

import PropTypes from "prop-types";

import { database } from "../../../firebase";

import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ComputerIcon from '@mui/icons-material/Computer';
import PhoneIcon from '@mui/icons-material/PhoneAndroid';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { ref } from 'firebase/database';
import { formatDateLong } from "../../helper/dateUtils";
import { styled } from '@mui/system';

import { useTranslation } from 'react-i18next';

import SearchBar from "../../SearchBar";

import {
  IconButton,
  List,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  ListItemButton,
  Box,
  Typography
} from "@mui/material";

const LoadRootDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > * + *': {
    marginLeft: theme.spacing(2),
  },
}))

const MurcsCircularProgress = styled(CircularProgress)({
  margin: 'auto',
})

const RoomListItem = (props) => {
  const { data, roomKey } = props
  const { i18n } = useTranslation();
  var createdDate = new Date(data.created)
  const formattedDate = formatDateLong(createdDate, i18n.language);

  return (
    <ListItemButton component={Link} to={"/retrospective/".concat(roomKey)}>
      <ListItemAvatar>
        <Avatar>
          <QuestionAnswerIcon color="secondary"/>
        </Avatar>
      </ListItemAvatar>
      <ListItemText 
        primary = {
        <Typography variant="body1" color="textPrimary">
          {data.name}
        </Typography>}
        secondary={formattedDate} />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="comments" label="Board" component={Link} to={"/retrospective/".concat(roomKey)}>
          <ComputerIcon color="secondary"/>
        </IconButton>
        <IconButton edge="end" aria-label="comments" label="Participate" component={Link} to={"/retrospective/".concat(roomKey).concat("/participate")}>
          <PhoneIcon color="secondary"/>
        </IconButton>
      </ListItemSecondaryAction>
    </ListItemButton>
  )
}


const RoomList = (props) => {
  const [rooms, loading, error] = useList(ref(database, "retrospective/publicrooms"))
  const [searchTerm, setSearchTerm] = useState("");
  const { t: translate } = useTranslation();

  const filteredRooms = rooms.filter(room => {
    return room.val().name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (

    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="subtitle1" color="textPrimary" component="div" sx={{ fontWeight: 500, fontSize: '0.9rem', px: 2, py: 1 }}>
          {translate('retro.roomListTitle')}
        </Typography>
        <SearchBar onChange={setSearchTerm} />
      </Box>

      {error && <strong>{translate('retro.errorMessage')}</strong>}
      {loading && <LoadRootDiv><MurcsCircularProgress /></LoadRootDiv>}

      <List>
        {typeof rooms !== 'undefined' && filteredRooms.map(room => (
          <RoomListItem key={room.key} roomKey={room.key} data={room.val()} />

        ))}

      </List>
    </>

  );
}


RoomList.propTypes = {
  user: PropTypes.object,

};

export default RoomList;
