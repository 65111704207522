import React from "react";

import { auth, database } from '../../../firebase'
import { GoogleAuthProvider, EmailAuthProvider } from "firebase/auth"

import PropTypes from "prop-types";

import { styled, useTheme } from '@mui/system';

import { useTranslation } from 'react-i18next';

import StyledFirebaseAuth from "../../helper/StyledFirebaseAuth.tsx";


import {
  Typography,
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import AccountProfileCard from '../AccountProfileCard'

const RootContainerGrid = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  width: '100%',
  margin: 0
}))

const RoomTitleTypography = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  textAlign: "center"
}))

const MurcsPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
}))

const HighlightedMurcsPaper = styled(MurcsPaper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.subtleBackground,
}))

const AccountPage = (props) => {
  const theme = useTheme();
  const { userData } = props;
  const [panelExpanded, setPanelExpanded] = React.useState(false)
  const { t: translate } = useTranslation();

  const fullBorderRadius = {
    borderRadius: '4px',
  }

  const noBottomRadius = {
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
  }

  const onlyBottomRadius = {
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  }

  const spacingBottom = {
    marginBottom: theme.spacing(2),
  }

  const handleSignIn = (event) => {

    const user = auth.currentUser

    if (typeof user !== 'undefined' && user !== null) {
      const userUpdate = {
        'displayName': typeof user.displayName !== 'undefined' && user.displayName !== null ? user.displayName : translate('account.unknownUser'),
        'email': typeof user.email !== 'undefined' && user.email !== null ? user.email : "",
        'emailVerified': typeof user.emailVerified !== 'undefined' && user.emailVerified !== null ? user.emailVerified : "",
        'phoneNumber': typeof user.phoneNumber !== 'undefined' && user.phoneNumber !== null ? user.phoneNumber : "",
        'provider': typeof user.providerData !== 'undefined' && user.providerData !== null && Array.isArray(user.providerData) && user.providerData.length > 0 ? user.providerData.map(userInfo => userInfo.providerId) : translate('account.anonymous'),
        'disabled': typeof user.disabled !== 'undefined' && user.disabled !== null ? user.disabled : false,
        'isAnonymous': user.isAnonymous,
        'creationTime': typeof user.metadata !== 'undefined' && user.metadata !== null && typeof user.metadata.creationTime !== 'undefined' && user.metadata.creationTime !== null ? user.metadata.creationTime : "",
        'lastSignInTime': typeof user.metadata !== 'undefined' && user.metadata !== null && typeof user.metadata.lastSignInTime !== 'undefined' && user.metadata.lastSignInTime !== null ? user.metadata.lastSignInTime : "",
        'lastVisit': Date.now(),
        'public/displayName': typeof user.displayName !== 'undefined' && user.displayName !== null ? user.displayName : translate('account.unknownUser'),
        'public/photoURL': typeof user.photoURL !== 'undefined' && user.photoURL !== null ? user.photoURL : ""
      }

      database.ref('data/users').child(auth.currentUser.uid).update(userUpdate)
    }
    return false
  }

  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.

    // We will display Google and Facebook as auth providers.
    signInOptions: [
      GoogleAuthProvider.PROVIDER_ID,
      EmailAuthProvider.PROVIDER_ID,
    ],

    autoUpgradeAnonymousUsers: true,

    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: handleSignIn
    }
  };

  const handlePanelChange = (event) => {
    setPanelExpanded(!panelExpanded)
  }

  const isAnonymousUser = () => {
    return typeof auth.currentUser !== 'undefined' && auth.currentUser !== null && auth.currentUser.isAnonymous
  }

  return (
    <RootContainerGrid container justifyContent="center" spacing={3} >
      <Grid xs={12} item>
        <RoomTitleTypography variant="h2" sx={{ color: theme.palette.primary.light }}>{translate('account.title')}</RoomTitleTypography>
      </Grid>

      <Grid item xs={12} sm={8} md={4}>
        <AccountProfileCard userData={userData} />
      </Grid>

      <Grid item xs={12} sm={8} md={8}>

        {isAnonymousUser() &&
          <>
            <HighlightedMurcsPaper sx={(!panelExpanded && noBottomRadius)}>
              <Typography variant="h6">
                {translate('account.signedInAnonymouslyTitle')}
              </Typography>
              <Typography variant="body1">
                {translate('account.signedInAnonymouslyDescription')}
              </Typography>

            </HighlightedMurcsPaper>

            <Accordion expanded={panelExpanded} slotProps={{ transition: { unmountOnExit: true } }} sx={[spacingBottom, (panelExpanded && fullBorderRadius), (!panelExpanded && onlyBottomRadius)]} onChange={handlePanelChange}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>{translate('account.migrateUserAccountTitle')}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ width: '100%', display: 'block' }}>
                <Box component="span" display="block" >
                  <Typography>
                    {translate('account.migrateUserAccountDescription')}
                  </Typography>
                </Box>

                <Box component="span" display="block" p={1} m={1} >
                  <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
                </Box>

              </AccordionDetails>
            </Accordion></>}


        <MurcsPaper>
          <Typography variant="h6">
            {translate('account.teamsTitle')}
          </Typography>
          <Typography variant="body2">
            {translate('account.teamsDescription')}
          </Typography>
          <br /> <br />
          <Typography variant="h5">
            <em>
              {translate('account.comingSoonPlaceholder')}
            </em>
          </Typography>

        </MurcsPaper>


      </Grid>


    </RootContainerGrid>
  );
}



AccountPage.propTypes = {
  userData: PropTypes.object
};

export default AccountPage;
// export default HomePage;
