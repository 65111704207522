import React from "react";

import { auth, database } from '../../../firebase' 

import PropTypes from "prop-types";

import { styled, useTheme } from '@mui/system';

import { useTranslation, Trans } from 'react-i18next';

import { formatDateLong } from "../../helper/dateUtils";

import { 
  Typography,
  Button,
  Paper,
  Avatar,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  CircularProgress,
  Divider,
  TextField,
} from "@mui/material";


import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import UpdateIcon from '@mui/icons-material/Update'
import LoginIcon from '@mui/icons-material/LockOpen'
import ProviderIcon from '@mui/icons-material/VpnKey'
import PersonIcon from '@mui/icons-material/Person'

const LoadRootDiv = styled('div')(({theme}) => ({
  display: 'flex',
  '& > * + *': {
    marginLeft: theme.spacing(2),
  },
}))

const MurcsCircularProgress = styled(CircularProgress)(({theme}) => ({
  margin: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  display: 'flex',
  '& > * + *': {
    marginLeft: theme.spacing(2),
  },
}))

const ProfileBox = styled(Box)(({theme}) => ({
  padding: theme.spacing(1),
    minWidth: 200,
}))

const LargeAvatar = styled(Avatar)(({theme}) => ({
  width: theme.spacing(16),
    height: theme.spacing(16),
}))

const ProfileNameTypography = styled(Typography)(({theme}) => ({
  marginTop: 10,
}))

const WordBreakList = styled(List)({
  wordBreak: 'break-word'
})

const MurcsPaper = styled(Paper)(({theme}) => ({
  padding: theme.spacing(2),
    color: theme.palette.text.secondary,
}))


const AccountProfileCard = (props) => {
  const theme = useTheme();
  const { userData } = props;
  const { t: translate, i18n } = useTranslation();

  const [tmpDisplayName, setTmpDisplayName] = React.useState('');
  const [displayNameEditMode, setDisplayNameEditMode] = React.useState(false);
  
  const handleEditUserNameClicked = (event) => {
    setTmpDisplayName(getUserName());
    setDisplayNameEditMode(true)
  }

  const handleDisplayNameChange = (event) => {
    setTmpDisplayName(event.target.value);
  }

  const handleEditUserNameSubmit = (event) => {
    event.preventDefault();

    if(tmpDisplayName.length >= 2) {
      database.ref("data/users").child(auth.currentUser.uid).child('public').child('displayName').set(tmpDisplayName);
      setTmpDisplayName('')
      setDisplayNameEditMode(false)
    } else {
      alert(translate('account.profileCard.userNameTooShort'))
    }

    
  }

  const getUserName = () => {
    return userData !== null && typeof userData.public === 'object' && typeof userData.public.displayName !== 'undefined' && userData.public.displayName !== null ? userData.public.displayName : ""
  }

  const getProfileImageUrl = () => {
    return userData !== null && typeof userData.public === 'object' && typeof userData.public.photoURL !== 'undefined' && userData.public.photoURL !== null ? userData.public.photoURL : ""
  }

  const getUserEmailAddress = () => {
    return userData !== null && typeof userData.email !== 'undefined' && userData.email !== null && userData.email.length > 0 ? userData.email : ""
  }

  const getUserProviderArray = () => {
    return userData !== null && typeof userData.provider !== 'undefined' ? userData.provider : null
  }

  const getUserCreatedDate = () => {
    const dateString = userData !== null && typeof userData.creationTime !== 'undefined' ? userData.creationTime : null
    if(dateString !== null) {
      const date = Date.parse(dateString)
      if(typeof date !== 'undefined' && date !== null) {
        return formatDateLong(date, i18n.language)
      }
      
    }
    return ""
  }

  const getUserLoginDate = () => {
    const dateString = userData !== null && typeof userData.lastSignInTime !== 'undefined' ? userData.lastSignInTime : null
    if(dateString !== null) {
      const date = Date.parse(dateString)
      if(typeof date !== 'undefined' && date !== null) {
        return formatDateLong(date, i18n.language)
      }
      
    }
    return ""
  }

  const getUserLastVisitDate = () => {
    const dateTimestamp = userData !== null && typeof userData.lastVisit !== 'undefined' ? userData.lastVisit : null
    if(dateTimestamp !== null) {
      const date = new Date(dateTimestamp)
      if(typeof date !== 'undefined' && date !== null) {
        return formatDateLong(date, i18n.language)
      }
      
    }
    return ""
  }

  const loadSpinner = (
    <LoadRootDiv><MurcsCircularProgress/></LoadRootDiv>
  )

  const content = (
    <>
    <ProfileBox align="center" >
        <LargeAvatar alt={getUserName()} src={getProfileImageUrl()}/> 
        {!displayNameEditMode ? (
          <ProfileNameTypography variant="h5">
            <strong>{getUserName()}</strong> 
            <IconButton onClick={handleEditUserNameClicked}><EditIcon fontSize="small"  color="secondary"/></IconButton>
          </ProfileNameTypography>) : (
            <form onSubmit={handleEditUserNameSubmit}>
            <ProfileNameTypography variant="h5">
              <TextField
                id="standard-full-width"
                name="userName"
                value={tmpDisplayName}
                helperText=""
                margin="dense"
                autoComplete='off'
                onChange={handleDisplayNameChange}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ 
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: theme.palette.primary.light
                    },
                  },
                }}
              />
              <div style={{ marginTop: "8px" }}>
                <Button variant="contained" type="submit" color="primary" disabled={tmpDisplayName.length < 2}>
                  {translate('account.save')}
                </Button>
              </div>
            </ProfileNameTypography>
          </form>          
          ) 
        }
      </ProfileBox>
      <WordBreakList>
        {process.env.NODE_ENV === 'development' && <ListItem>
          <ListItemIcon><PersonIcon color="secondary"/></ListItemIcon>
          <ListItemText>{auth.currentUser.uid}</ListItemText>
        </ListItem>}

        {getUserEmailAddress() !== "" && <ListItem>
          <ListItemIcon><EmailIcon color="secondary"/></ListItemIcon>
          <ListItemText>{getUserEmailAddress()}</ListItemText>
          {/* <ListItemSecondaryAction><IconButton><EditIcon fontSize="small"/></IconButton></ListItemSecondaryAction> */}
        </ListItem>}
        <Divider/>

        {userData !== null && userData.isAnonymous === true && <ListItem>
          <ListItemIcon><ProviderIcon color="secondary"/></ListItemIcon>
          <ListItemText secondary={<Trans i18nKey="account.profileCard.anonymousUserSessionDescription" components={{em: <em/>}} />}>{translate('account.profileCard.anonymousUser')}</ListItemText>
        </ListItem>}

        {getUserProviderArray() !== null && <ListItem>
          <ListItemIcon><ProviderIcon color="secondary"/></ListItemIcon>
          <ListItemText>{getUserProviderArray()}</ListItemText>
        </ListItem>}
        <Divider/>

        {getUserCreatedDate() !== "" && <ListItem>
          <ListItemIcon><AccessTimeIcon color="secondary"/></ListItemIcon>
          <ListItemText secondary={translate('account.profileCard.memberSince')}>{getUserCreatedDate()}</ListItemText>
        </ListItem>}
        {getUserLoginDate() !== "" && <ListItem>
          <ListItemIcon><LoginIcon color="secondary"/></ListItemIcon>
          <ListItemText secondary={translate('account.profileCard.lastSignIn')}>{getUserLoginDate()}</ListItemText>
        </ListItem>}
        {getUserLastVisitDate() !== "" && <ListItem>
          <ListItemIcon><UpdateIcon color="secondary"/></ListItemIcon>
          <ListItemText secondary={translate('account.profileCard.lastVisit')}>{getUserLastVisitDate()}</ListItemText>
        </ListItem>}
      </WordBreakList>
    </>
  )

  return (
    <MurcsPaper>

      {userData !== null ? content : loadSpinner }

    </MurcsPaper>
  );
}



AccountProfileCard.propTypes = {
  userData: PropTypes.object
};

export default AccountProfileCard;
// export default HomePage;
