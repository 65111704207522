import React from "react";

import PropTypes from "prop-types";

import { useObject } from 'react-firebase-hooks/database';
import { auth, database } from "../../../firebase";


import { useParams } from "react-router-dom";
import { styled } from '@mui/system';


import ParticipateContent from '../ParticipateContent'
import PasswordScreen from '../../PasswordScreen'
import { ref } from 'firebase/database';

import { 

  CircularProgress,

} from "@mui/material";

const RootDiv = styled('div')({
  flexGrow: 1,
})

const LoadRootDiv = styled('div')(({theme}) => ({
  display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
      marginTop: 100
    },
}))

const MurcsCircularProgress = styled(CircularProgress)({
  marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 100
})


const PlanningParticipatePage = (props) => {
  const { userData } = props;
  const { roomId } = useParams();

  const [room, loading, error] = useObject(ref(database, "planning/rooms/" + roomId));

  const roomVal = (typeof room !== 'undefined') ? room.val() : {}

  return (
    
    <React.Fragment>
  
      <RootDiv >
        {error && <strong>Error: {error}</strong>}
        {loading && <LoadRootDiv ><MurcsCircularProgress/></LoadRootDiv>}
        
        {/* // if not loading and no error, but username is not in users List, provide PasswordScreen... if user is in List, show room. */}
        {(!loading && !error) ? (typeof room !== 'undefined' && room !== null && roomVal !== null && ((typeof roomVal.users !== 'undefined' &&  Object.keys(roomVal.users).includes(auth.currentUser.uid)) || roomVal.creator === auth.currentUser.uid)) ? <ParticipateContent userData={userData} roomId={roomId} room={room} /> : <PasswordScreen endpoint="/murcs/planning/authenticate" roomId={roomId} room={room} /> : ""}
        

      </RootDiv>
      
    </React.Fragment>
  );
}

PlanningParticipatePage.propTypes = {
  user: PropTypes.object,
  userData: PropTypes.object
};

export default PlanningParticipatePage;
