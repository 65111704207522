import React from "react";

import PropTypes from "prop-types";

import { useObject } from 'react-firebase-hooks/database';
import { auth, database } from "../../../firebase";


import { useParams } from "react-router-dom";
import { styled } from '@mui/system';


import RoomContent from '../RoomContent'
import PasswordScreen from '../../PasswordScreen'
import { ref } from 'firebase/database';

import { 
  CircularProgress,

} from "@mui/material";

const LoadRootDiv = styled('div')(({theme}) => ({
  display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
      marginTop: 100
    },
}))

const RootDiv = styled('div')({
  flexGrow: 1,
})

const MurcsCircularProgress = styled(CircularProgress)({
  marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 100
})

const PlanningRoomPage = (props) => {
  const { roomId } = useParams();

  const [room, loading, error] = useObject(ref(database,"planning/rooms/" + roomId))

  const roomVal = (typeof room !== 'undefined') ? room.val() : {}

  return (
    <React.Fragment>
    
  
      <RootDiv >
        {error && <strong>Error: {error}</strong>}
        {loading && <LoadRootDiv><MurcsCircularProgress /></LoadRootDiv>}
        
        {/* // if not loading and no error, but username is not in users List, provide PasswordScreen... if user is in List, show room. */}
        {(!loading && !error) ? (typeof room !== 'undefined' && room !== null && roomVal !== null && ((typeof roomVal.users !== 'undefined' &&  Object.keys(roomVal.users).includes(auth.currentUser.uid)) || roomVal.creator === auth.currentUser.uid)) ? <RoomContent roomId={roomId} room={room} /> : <PasswordScreen endpoint="/murcs/planning/authenticate" roomId={roomId} room={room} /> : ""}
        

      </RootDiv>
      
    </React.Fragment>
    
  );
}

PlanningRoomPage.propTypes = {
  user: PropTypes.object
};

export default PlanningRoomPage;
