import React from "react";

import PropTypes from "prop-types";

import { Link, Navigate } from "react-router-dom";

import { auth } from "../../firebase";

import { styled, useTheme } from '@mui/system';

import { useTranslation, Trans } from 'react-i18next';

import { 
  Typography, 
  Grid, 
  Button, 
  Paper, 
  ButtonBase 
} from "@mui/material";

import ScheduleIcon from '@mui/icons-material/Schedule';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ValidUserHelper from "../helper/ValidUserHelper";

const RoomTitleTypography = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  textAlign: "center"
}));

const DescriptionTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const TopSpaceGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const RootContainerGrid = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  width: '100%',
  margin: 0
}));

const TopSpaceRootContainerGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),
  flexGrow: 1,
  padding: theme.spacing(2),
  width: '100%',
  margin: 0
}));

const MurcsPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

const MurcsGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2)
}));

const HomePage = (props) => {
  const { t: translate } = useTranslation();
  const theme = useTheme();
  const { user, userData, onLogout } = props;

  const handleLogout = (event) => {
    onLogout();
  };

  const isValidUser = ValidUserHelper.isValidUser(user);

  return (
    <RootContainerGrid container justifyContent="center" alignItems="center" spacing={3}>
      <MurcsGrid xs={12} item>
        <RoomTitleTypography variant="h2" sx={{ color: theme.palette.primary.light }}>{translate('homepage.welcomeTitle')}</RoomTitleTypography>
      </MurcsGrid>

      {!isValidUser ? (
        <MurcsGrid item xs={12} sm={8} textAlign="center">
          <Typography variant="body1">
           {translate('homepage.welcomeDescriptionNotValid')}
          </Typography>
          <Typography variant="body1" sx={{ mt: theme.spacing(1) }}>
            <Trans
              i18nKey="notLoggedIn.useWithGoogle"
              components={{
                1: <span style={{ fontWeight: 'bold', color: theme.palette.primary.veryLight }} />,
                3: <span style={{ fontWeight: 'bold', color: theme.palette.primary.veryLight }} />,
                5: <span style={{ fontWeight: 'bold', color: theme.palette.primary.veryLight }} />
              }}
            />
          </Typography>
        </MurcsGrid>
      ) : (
        <MurcsGrid item xs={12} sm={8} textAlign="center">
          <Typography variant="body1">
           {translate('homepage.welcomeDescription')}
          </Typography>
        </MurcsGrid>
      )}

      {isValidUser ? (
        <TopSpaceRootContainerGrid sm={8} container spacing={2}>
          <Grid item xs={6} sm={6} md={6}>
            <ButtonBase focusRipple component={Link} to="/planning" style={{ width: '100%' }}>
              <MurcsPaper style={{ width: '100%' }}>
                <Typography align="center" variant="h1"><ScheduleIcon style={{ fontSize: '0.85em' }} /></Typography>
                <Typography align="center" variant="h6" noWrap={true}>{translate('homepage.planningTitle')}</Typography>
              </MurcsPaper>
            </ButtonBase>
            <DescriptionTypography variant="body2">
            {translate('homepage.planningDescription')}
            </DescriptionTypography>
          </Grid>

          <Grid item xs={6} sm={6} md={6}>
            <ButtonBase focusRipple component={Link} to="/retrospective" style={{ width: '100%' }}>
              <MurcsPaper style={{ width: '100%' }}>
                <Typography align="center" variant="h1"><QuestionAnswerIcon style={{ fontSize: '0.85em' }} /></Typography>
                <Typography align="center" variant="h6" noWrap={true}>{translate('homepage.retrospectiveTitle')}</Typography>
              </MurcsPaper>
            </ButtonBase>
            <DescriptionTypography variant="body2">
              {translate('homepage.retrospectiveDescription')}
            </DescriptionTypography>
          </Grid>
        </TopSpaceRootContainerGrid>
      ) : null}

      {!auth.currentUser && <Navigate replace to="/login" />}

      {auth.currentUser && !isValidUser && (
        <TopSpaceGrid item xs={12} justifyContent="center" align="center">
          <Button variant="contained" size="large" color="primary" onClick={handleLogout}>{translate('profile.logoutTitle')}</Button>
        </TopSpaceGrid>
      )}

    </RootContainerGrid>
  );
};

HomePage.propTypes = {
  // Properties
  user: PropTypes.object,
  userData: PropTypes.object,
  // Events
  onLogout: PropTypes.func.isRequired,
};

export default HomePage;
