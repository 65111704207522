import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter, Navigate, Route, Routes, Link } from "react-router-dom";
import { styled } from '@mui/system';

import { useTranslation } from 'react-i18next';

import HomePage from "../HomePage";
import ContactPage from "../ContactPage";
import AccountPage from "../account/AccountPage/AccountPage";
import PlanningPage from "../planning/PlanningPage";
import PlanningRoomPage from "../planning/PlanningRoomPage";
import PlanningParticipatePage from "../planning/PlanningParticipatePage";
import NotFoundPage from "../NotFoundPage";
import ChangelogPage from "../changelog/ChangelogPage";
import RetrospectivePage from "../retrospective/RetrospectivePage";
import RetrospectiveRoomPage from "../retrospective/RetrospectiveRoomPage";
import RetrospectiveParticipatePage from "../retrospective/RetrospectiveParticipatePage";
import Bar from "../Bar";
import NotLoggedInPage from "../NotLoggedInPage";
import PreferencesPage from "../PreferencesPages";
import ProtectedRoute from "../helper/ProtectedRouteHelper";
import { Typography, Grid } from "@mui/material";

const appVersion = process.env.REACT_APP_VERSION;

const MurcsFooter = styled('footer')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '3.5rem',
  backgroundColor: theme.palette.primary.subtleBackground
}));

const ChangelogTypography = styled(Typography)(({ theme }) => ({
  align: "right",
  textDecoration: 'none',
}));

const RootContainerGrid = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
}));

const ContentWrapDiv = styled('div')(({ theme }) => ({
  paddingBottom: '3.5rem',
}));

const PageContainerDiv = styled('div')(({ theme }) => ({
  position: 'relative',
  minHeight: '100vh',
}));

const Router = (props) => {
  const { user, userData, theme, onLogout } = props;
  const { t: translate } = useTranslation();

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <PageContainerDiv>
        <ContentWrapDiv>
          <Routes>
            <Route path="/" exact element={[
              <Bar
                user={user}
                userData={userData}
                theme={theme}
                destination="home"
                title={translate('router.home')}
                onLogout={onLogout}
              />,
              <HomePage user={user} userData={userData} onLogout={onLogout} />]
            } />

            <Route path="/home" element={<Navigate replace to="/" />} />

            <Route path="/contact" exact element={[
              <Bar
                user={user}
                userData={userData}
                theme={theme}
                destination="contact"
                title={translate('router.contact')}
                onLogout={onLogout}
              />,
              <ContactPage userData={userData} />
            ]}
            />

            <Route path="/changelog" exact element={[
              <Bar
                user={user}
                userData={userData}
                theme={theme}
                destination="changelog"
                title="Changelog"
                onLogout={onLogout}
              />,
              <ChangelogPage userData={userData} />
            ]}
            />

            <Route path="/preferences" exact element={[
              <Bar
                user={user}
                userData={userData}
                theme={theme}
                destination="preferences"
                title={translate('router.preferences')}
                onLogout={onLogout}
              />,
              <ProtectedRoute user={user} userData={userData}>
                <PreferencesPage userData={userData} />
              </ProtectedRoute>
            ]} />

            <Route path="/login" exact element={[
              <Bar
                user={user}
                userData={userData}
                theme={theme}
                destination="login"
                title={translate('router.login')}
                onLogout={onLogout}
              />,
              user ? <Navigate replace to="/" /> : <NotLoggedInPage />
            ]} />

            <Route path="/planning/:roomId/participate" element={[
              <Bar
                user={user}
                userData={userData}
                theme={theme}
                destination="planning"
                title={translate('router.planning')}
                onLogout={onLogout}
              />,
              <ProtectedRoute user={user} userData={userData}>
                <PlanningParticipatePage userData={userData} />
              </ProtectedRoute>
            ]} />

            <Route path="/planning/:roomId" element={[
              <Bar
                user={user}
                userData={userData}
                theme={theme}
                destination="planning"
                title={translate('router.planning')}
                onLogout={onLogout}
              />,
              <ProtectedRoute user={user} userData={userData}>
                <PlanningRoomPage userData={userData} />
              </ProtectedRoute>
            ]} />

            <Route path="/planning" element={[
              <Bar
                user={user}
                userData={userData}
                theme={theme}
                destination="planning"
                title={translate('router.planning')}
                onLogout={onLogout}
              />,
              <ProtectedRoute user={user} userData={userData}>
                <PlanningPage userData={userData} />
              </ProtectedRoute>
            ]} />

            <Route path="/retrospective/:roomId/participate" element={[
              <Bar
                user={user}
                userData={userData}
                theme={theme}
                destination="retrospective"
                title={translate('router.retrospective')}
                onLogout={onLogout}
              />,
              <ProtectedRoute user={user} userData={userData}>
                <RetrospectiveParticipatePage userData={userData} /> 
              </ProtectedRoute>
            ]} />

            <Route path="/retrospective/:roomId" element={[
              <Bar
                user={user}
                userData={userData}
                theme={theme}
                destination="retrospective"
                title={translate('router.retrospective')}
                onLogout={onLogout}
              />,
              <ProtectedRoute user={user} userData={userData}>
                <RetrospectiveRoomPage userData={userData} />
              </ProtectedRoute>  
            ]} />

            <Route path="/retrospective" element={[
              <Bar
                user={user}
                userData={userData}
                theme={theme}
                destination="retrospective"
                title={translate('router.retrospective')}
                onLogout={onLogout}
              />,
              <ProtectedRoute user={user} userData={userData}>
                <RetrospectivePage userData={userData} />
              </ProtectedRoute>  
            ]} />

            <Route path="/account" element={[
              <Bar
                user={user}
                userData={userData}
                theme={theme}
                destination="account"
                title={translate('router.account')}
                onLogout={onLogout}
              />,
              <ProtectedRoute user={user} userData={userData}>
                <AccountPage userData={userData} />
              </ProtectedRoute>
            ]} />

            <Route element={<NotFoundPage />} />
          </Routes>
        </ContentWrapDiv>
        <MurcsFooter >
          <RootContainerGrid container alignItems="center">
            <Grid item xs={8} >
              <Typography variant="body2">Copyright @ MURCS.app</Typography>
            </Grid>
            <Grid item xs={4} align="right">
              <ChangelogTypography variant="body2" justifyContent="right" component={Link} to="/changelog" align="right" sx={{ color: theme.palette.primary.veryLight }}>Version {appVersion}</ChangelogTypography>
            </Grid>
          </RootContainerGrid>
        </MurcsFooter>
      </PageContainerDiv>
    </BrowserRouter>
  );
};

Router.propTypes = {
  // // Properties
  user: PropTypes.object,
  userData: PropTypes.object,
  theme: PropTypes.object.isRequired,
  // roles: PropTypes.array.isRequired,
  // bar: PropTypes.element,

  // Functions
  onLogout: PropTypes.func.isRequired,

};

export default Router;
