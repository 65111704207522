import React from "react";

import PropTypes from "prop-types";

import { useObject } from 'react-firebase-hooks/database';
import { auth, database } from "../../../firebase";

import { useTranslation } from 'react-i18next'

import { Link } from "react-router-dom";
import { styled } from '@mui/system';


import MoodSliderView from '../MoodSliderView'
import NotesList from '../NotesList'
import { child, ref } from 'firebase/database';

import {
  Button,
  Typography,
  TextField,
  Grid,
  CircularProgress,
  Divider,
  useTheme
} from "@mui/material";


const RootDiv = styled('div')({
  flexGrow: 1,
  margin: 'auto',
})

const ContainerGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  margin: 0
}))

const LoadRootDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > * + *': {
    marginLeft: theme.spacing(2),
  },
}))

const MurcsCircularProgress = styled(CircularProgress)({
  margin: 'auto',
})

const MurcsDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2)
}))

const SubTitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.veryLight
}))

const ParticipateContent = (props) => {
  const { userData } = props;
  const { roomId, room } = props;
  const [participantData, loading] = useObject(child(ref(database), "retrospective/participants/" + roomId + "/" + auth.currentUser.uid))
  const roomVal = (typeof room !== 'undefined') ? room.val() : {}
  const theme = useTheme();
  const { t: translate } = useTranslation();

  const getInitialUserName = () => {
    // take from userData object
    if (typeof userData !== 'undefined' && userData !== null && typeof userData.public == 'object' && userData.public !== null && typeof userData.public.displayName !== 'undefined' && userData.public.displayName !== null) return userData.public.displayName
    // take from auth user
    return (auth.currentUser.displayName !== null && auth.currentUser.displayName.length > 0) ? auth.currentUser.displayName : "Unknown"
  }


  React.useEffect(() => {


    if (!loading && participantData !== null && !participantData.exists()) {
      // loading has fiinished and there is no user entry
      const initialData = {
        name: getInitialUserName(),
        mood: 3,
        moodVisible: false,
      }
      console.log("Adding initial user data")
      database.ref("retrospective/participants").child(roomId).child(auth.currentUser.uid).set(initialData)

    }
  }, [participantData])

  const participantDataVal = (typeof participantData !== 'undefined' && participantData !== null) ? participantData.val() : {}

  const handleNameChange = (event) => {
    const name = event.target.value

    database.ref("retrospective/participants").child(roomId).child(auth.currentUser.uid).update({
      name: name
    })
  }

  const content = (
    <RootDiv>
      <ContainerGrid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Button component={Link} to={"/retrospective/".concat(roomId)} style={{ float: "right", color: theme.palette.primary.veryLight }}>
            &#8592; {translate('retro.participateContent.backButtonTitle')}
          </Button>
          <Typography variant="h4" sx={{ color: theme.palette.primary.light }}>
            {roomVal.name}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">{translate('retro.participateContent.username')}</Typography>
          <TextField
            id="nameField"
            label={translate('retro.participateContent.usernameLabel')}
            placeholder={translate('retro.participateContent.usernamePlaceholder')}
            helperText=""
            fullWidth
            onChange={handleNameChange}
            value={participantDataVal !== null ? participantDataVal.name : ""}
            type="text"
            margin="normal"
            sx={{ 
              '& label.Mui-focused': {
                color: theme.palette.primary.veryLight
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.primary.light
                },
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">{translate('retro.participateContent.unpinnedNotes')}</Typography>

          <NotesList roomId={roomId} visible={false} add={true} limitToUid={auth.currentUser.uid} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">{translate('retro.participateContent.notesOnBoard')}</Typography>

          <SubTitleTypography variant="overline">{translate('retro.participateContent.positive')}</SubTitleTypography>
          <NotesList roomId={roomId} visible={true} add={false} limitToLocation="positive" />
          <MurcsDivider />
          <SubTitleTypography variant="overline">{translate('retro.participateContent.negative')}</SubTitleTypography>
          <NotesList roomId={roomId} visible={true} add={false} limitToLocation="negative" />
          <MurcsDivider />
          <SubTitleTypography variant="overline">{translate('retro.participateContent.optimize')}</SubTitleTypography>
          <NotesList roomId={roomId} visible={true} add={false} limitToLocation="optimize" />
          <MurcsDivider />
          <SubTitleTypography variant="overline">{translate('retro.participateContent.check')}</SubTitleTypography>
          <NotesList roomId={roomId} visible={true} add={false} limitToLocation="check" />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">{translate('retro.participateContent.mood')}</Typography>
          <MoodSliderView data={participantDataVal} roomId={roomId} />
        </Grid>



      </ContainerGrid>
    </RootDiv>
  )

  const loadSpinner = (
    <LoadRootDiv><MurcsCircularProgress /></LoadRootDiv>
  )

  return (

    <React.Fragment>

      {!loading ? content : loadSpinner}

    </React.Fragment>

  )
}

ParticipateContent.propTypes = {
  user: PropTypes.object,
  userData: PropTypes.object
};

export default ParticipateContent;
