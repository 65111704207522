import React from "react";

import PropTypes from "prop-types";

import { auth, database } from "../../../firebase";

import { ref, remove } from 'firebase/database';

import { styled } from '@mui/system';

import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';

import {mapPlanningValue} from '../PlanningValueMapper'

import { 
  Typography,
  Grid,
  Paper,
  IconButton,
  useTheme
} from "@mui/material";

const ContainerGrid = styled(Grid)(({theme}) => ({
  padding: theme.spacing(2),
}))

const ParticipantItem = (props) => {
  const theme = useTheme();
  const { data, onParticipantClicked, onDeleteParticipantClicked, pickedValue, roomVal, canPerformActions, isSplitScreen} = props
  const canShowDeleteButton = canPerformActions && ((isSplitScreen && auth.currentUser.uid !== roomVal.creator) || !isSplitScreen);
  
  const handleSelection = (event) => {
    onParticipantClicked(data[0])
  }

  const handleDeleteClick = (event) => {
    // Stop the event from bubbling up to the Paper onClick handler
    event.stopPropagation();
    onDeleteParticipantClicked(data[0]);
  }

  const option = {
    padding: theme.spacing(1),
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: theme.palette.primary.subtleBackground,
    minHeight: 160,
  }

  const optionSelected = {
    padding: theme.spacing(1),
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: theme.palette.primary.greenBackground,
    minHeight: 160,
  }

  const optionDisagree = {
    padding: theme.spacing(1),
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: theme.palette.primary.redBackground,
    minHeight: 160,
  }
  
  const username = {
    // display: 'inline',
    // whiteSpace: 'nowrap',
    // textOverflow: 'clip',
  }

  return (
    <Paper variant="outlined" onClick={handleSelection} elevation={0} style={!canPerformActions || (!data[1].visible || pickedValue === null || data[1].value === -1) ? option : (pickedValue === data[1].value ? optionSelected : optionDisagree)}>
      <Grid container>
        {canShowDeleteButton && (
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <IconButton sx={{ color: theme.palette.primary.light }} onClick={handleDeleteClick}>
              <DeleteIcon  />
            </IconButton>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1" noWrap={false} style={{ username }}>
            {data[1].name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {data[1].visible ? (
            <Typography variant="h1">{mapPlanningValue(data[1].value, roomVal.values)}</Typography>
          ) : data[1].value === -1 ? (
            <Typography variant="h1" style={{ color: '#CCCCCC' }}>?</Typography>
          ) : (
            <Typography variant="h1">
              <CheckIcon style={{ fontSize: '0.85em', color: '#CCCCCC' }} />
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}


const RoomCards = (props) => {

  const { roomId, participants, loading, pickedValue, roomVal , canPerformActions , isSplitScreen} = props;

  const participantsVal = (typeof participants !== 'undefined' && participants !== null) ? participants.val() : {}
  
  const handleParticipantClicked = (data) => {
    console.log("Participant clicked " + data)

    database.ref("planning/participants").child(roomId).child(data).child("visible").set(!participantsVal[data].visible)

  }

  const handleDeleteParticipantClicked = (participantId) => {
    console.log("Delete icon clicked for participant ", participantId)

    if (canPerformActions) {
        remove(ref(database, `planning/participants/${roomId}/${participantId}`))
    }

  }

  return (
  
    <> 
      
      <ContainerGrid container spacing={3}>
        {typeof participantsVal !== 'undefined' && participantsVal !== null && Object.entries(participantsVal).map((x) => (
          <Grid key={x[0]} item xs={4} sm={isSplitScreen ? 4 : 3} md={isSplitScreen ? 5 : 2} lg={isSplitScreen ? 4 : 2}>
            <ParticipantItem data={x} participantId={x} loading={loading} pickedValue={pickedValue} roomVal={roomVal} onParticipantClicked={handleParticipantClicked} onDeleteParticipantClicked={handleDeleteParticipantClicked} canPerformActions={canPerformActions} isSplitScreen={isSplitScreen}/>
          </Grid>
        ))}
      </ContainerGrid>
    </>
    
  );
}


RoomCards.propTypes = {
  user: PropTypes.object,

};

export default RoomCards;
