import React from "react";

import PropTypes from "prop-types";

import { useObject } from 'react-firebase-hooks/database';
import { auth, database } from "../../../firebase";


import { Link } from "react-router-dom";
import { styled } from '@mui/system';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import OptionList from '../OptionList'
import { child, ref, remove } from 'firebase/database';

import { useTranslation } from 'react-i18next';

import {mapPlanningValue} from '../PlanningValueMapper'

import { 
  Button,
  Typography,
  TextField,
  Grid,
  Paper,
  CircularProgress,
  useTheme
} from "@mui/material";

const LoadRootDiv = styled('div')(({ theme }) => ({
  display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
}))

const MurcsCircularProgress = styled(CircularProgress)(({ theme }) => ({
  margin: 'auto',
}))

const MurcsVisibilityIcon = styled(VisibilityIcon)(({ theme }) => ({
  position: 'absolute',
    bottom: 0,
    left: 0,
    margin: theme.spacing(2),
    color: theme.palette.primary.subtleIcon,
}))

const MurcsVisibilityOffIcon= styled(VisibilityOffIcon)(({ theme }) => ({
  position: 'absolute',
    bottom: 0,
    left: 0,
    margin: theme.spacing(2),
    color: theme.palette.primary.subtleIcon,
}))

const ParticipantCardPaper = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: theme.palette.primary.subtleBackground,
    minHeight: 300,
    maxWidth:250,
    margin: 'auto',
    position: 'relative'
}))

const MurcsPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
}))

const CardValueTypography = styled(Typography)(({ theme }) => ({
  paddingTop: 50
}))

const RootDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
    margin: 'auto',
}))

const ContainerGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
    width: '100%',
    margin: 0
}))


const ParticipateContent = (props) => {
  const { userData } = props;
  const { roomId, room } = props;
  const theme = useTheme();
  const { isSplitScreen } = props;
  const [participantData, loading] = useObject(child(ref(database),"planning/participants/" + roomId + "/" + auth.currentUser.uid));
  const roomVal = (typeof room !== 'undefined') ? room.val() : {}
  const { t: translate } = useTranslation();

  const getInitialUserName = () => {
    // take from userData object
    if(typeof userData !== 'undefined' && userData !== null && typeof userData.public == 'object' && userData.public !== null && typeof userData.public.displayName !== 'undefined' && userData.public.displayName !== null) return userData.public.displayName
    // take from auth user
    return (auth.currentUser.displayName !== null && auth.currentUser.displayName.length > 0) ? auth.currentUser.displayName : "Unknown"
  }

  React.useEffect(() => {
    
    console.log(auth.currentUser)
    if(!loading && participantData !== null && !participantData.exists()) {
      // loading has finished and there is no user entry
      const initialData = {
        name: getInitialUserName(),
        value: -1,
        visible: false,
      }
      console.log("Adding initial user data")
      database.ref("planning/participants").child(roomId).child(auth.currentUser.uid).set(initialData)

    }
  }, [participantData])

  const userDataVal = (typeof participantData !== 'undefined' && participantData !== null) ? participantData.val() : {}

  const handleOnOptionPicked = (option) => {
    console.log("Option chosen: " + option)
    database.ref("planning/participants").child(roomId).child(auth.currentUser.uid).update({
      value: option,
      visible: false
    })
  }

  const handleNameChange = (event) => {
    const name = event.target.value

    database.ref("planning/participants").child(roomId).child(auth.currentUser.uid).update({
      name: name
    })
  }

  const leaveRoom = () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      remove(ref(database, `planning/participants/${roomId}/${currentUser.uid}`))
      .then(() => {
        console.log(`Participant ${currentUser.uid} removed from room ${roomId}`);
      })
      .catch((error) => {
        console.error('Error removing participant:', error.message);
      });
    }
  };

  const content = (
    <RootDiv>
        <ContainerGrid container spacing={3} justifyContent="center" alignItems="center">
          {!isSplitScreen && (
            <Grid item xs={12}>
                <Button component={Link} to={"/planning/".concat(roomId)}  style={{ float: "right", color: theme.palette.primary.veryLight }}>
                &#8592; {translate('planning.participateContent.backButtonTitle')}
                </Button> 
                <Typography variant="h4" sx={{ color: theme.palette.primary.light }}>
                  {roomVal.name}
                </Typography> 
            </Grid>
          )}

        

          <Grid item  xs={12}>
            <ParticipantCardPaper elevation={0} variant="outlined">
              
                <TextField
                  id="nameField"
                  label={translate('planning.participateContent.name')}
                  placeholder={translate('planning.participateContent.usernamePlaceholder')}
                  helperText=""
                  fullWidth
                  onChange={handleNameChange}
                  value={userDataVal !== null ? userDataVal.name : ""}
                  type="text"
                  margin="normal"
                  sx={{ 
                    '& label.Mui-focused': {
                      color: theme.palette.primary.veryLight
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: theme.palette.primary.light
                      },
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              
              
              <CardValueTypography variant="h1"> {(userDataVal !== null) ? mapPlanningValue(userDataVal.value, roomVal.values) : "..."}</CardValueTypography>
             
              {userDataVal !== null && userDataVal.visible ? <MurcsVisibilityIcon /> : <MurcsVisibilityOffIcon /> }

            </ParticipantCardPaper>
          </Grid>
        
          <Grid item>
            <MurcsPaper>
              <OptionList roomId={roomId} room={room} onOptionPicked={handleOnOptionPicked} />
            </MurcsPaper>
          </Grid>

          {!isSplitScreen && (
            <Grid item xs={12}>
              <Button variant="contained" color="error" onClick={leaveRoom} component={Link} to={"/planning/".concat(roomId)}>
              {translate('planning.participateContent.leaveRoom')}
              </Button>
            </Grid>
          )}

        </ContainerGrid>

      </RootDiv>
  )

  const loadSpinner = (
    <LoadRootDiv ><MurcsCircularProgress/></LoadRootDiv>
  )

  return (
    <React.Fragment>
    
      {!loading ? content : loadSpinner}
      
    </React.Fragment>
  )
}

ParticipateContent.propTypes = {
  user: PropTypes.object,
  userData: PropTypes.object
};

export default ParticipateContent;
