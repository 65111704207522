import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateShort } from "../helper/dateUtils";
import { 
    Card,
    CardContent,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Badge,
    Box,
    useTheme
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const Changelog = ({ entries }) => {
    const theme = useTheme();
    const { t: translate, i18n } = useTranslation();
    const appVersion = process.env.REACT_APP_VERSION;
    console.log('App Version:', appVersion);
    console.log('Entry Version:', entries.map(entry => entry.version));

    return (
        <Box style={{ margin: '20px' }}>
            {entries.map((entry, index) => (
                <Card key={index} sx={{ marginBottom: 2 }}>
                    <CardContent>
                    <Typography variant="h5" gutterBottom component="div" sx={{ color: theme.palette.primary.veryLight }}>
                            {'Version'} {entry.version} - {formatDateShort(entry.date, i18n.language)}
                            {entry.version === appVersion && (
                                <Badge badgeContent={translate('changelog.badgeCurrentVersion')} color="badge" sx={{ marginLeft: 5, marginBottom: 0.5, "& .MuiBadge-badge": {color: "white",backgroundColor: theme.palette.primary.badge} }}></Badge>
                            )}
                        </Typography>
                        <List>
                            {entry.changes.map((change, idx) => (
                                <ListItem key={idx}>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon sx={{ fontSize: 'small' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={translate(change)} />
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            ))}
        </Box>
    );
};

export default Changelog;
