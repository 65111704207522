import React from "react";

import PropTypes from "prop-types";

import { styled, useTheme } from '@mui/system';

import { common } from "@mui/material/colors";

import {
  Typography

} from "@mui/material";

const MoodContainerDiv = styled('div')(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(10),
  minHeight: 300,
}))

const OneDiv = styled('div')({
  position: 'absolute',
  width: 50,
  display: 'inline-block',
  left: '0%',
})

const TwoDiv = styled('div')({
  position: 'absolute',
  width: 50,
  display: 'inline-block',
  left: '25%'
})

const ThreeDiv = styled('div')({
  position: 'absolute',
  width: 50,
  display: 'inline-block',
  left: '50%'
})

const FourDiv = styled('div')({
  position: 'absolute',
  display: 'inline-block',
  left: '75%'
})

const FiveDiv = styled('div')({
  position: 'absolute',
  display: 'inline-block',
  right: 0
})

const MoodBarDiv = styled('div')({
  width: '100%',
  height: 4,
  background: 'linear-gradient(90deg, rgba(232, 134, 107,1) 0%, rgba(182, 232, 107,1) 100%)',
  borderRadius: 2,

  position: "absolute",
  top: 30,
})


const MoodOverview = (props) => {
  const theme = useTheme();
  const { data, showNames } = props;
  const [oldData, setOldData] = React.useState(data)
  const [changedKeys, setChangedKeys] = React.useState([])


  const percentage = value => {
    return ((value - 1.0) / 4.0 * 100.0).toFixed(0) + "%"
  }



  React.useEffect(() => {

    const newChangedEntries = [];

    if (typeof data !== 'undefined' && data !== null) {
      Object.entries(data).map((entry, index) => {
        if (typeof oldData[entry[0]] !== 'undefined' && oldData[entry[0]] !== null && (oldData[entry[0]].moodVisible !== entry[1].moodVisible || oldData[entry[0]].mood !== entry[1].mood)) { newChangedEntries.push(entry[0]) } else { }
      });
    }

    setChangedKeys(newChangedEntries)

    setOldData(data)
  }, [data])


  return (

    <>
      <MoodContainerDiv>
        <OneDiv><Typography variant="h5">1</Typography></OneDiv>
        <TwoDiv><Typography variant="h5">2</Typography></TwoDiv>
        <ThreeDiv><Typography variant="h5">3</Typography></ThreeDiv>
        <FourDiv><Typography variant="h5">4</Typography></FourDiv>
        <FiveDiv><Typography variant="h5">5</Typography></FiveDiv>
        <MoodBarDiv></MoodBarDiv>
        {data !== null && Object.entries(data).filter(entry => entry[1].moodVisible).sort((a, b) => a[1].mood - b[1].mood).map((entry, index) => (
          <div key={entry[0]} style={{ position: 'absolute', width: 100, top: (index % (Math.min(10, Object.keys(data).length / 2))) * 30 + 40, visibility: entry[1].moodVisible ? 'visible' : 'hidden', display: 'inline-block', left: percentage(entry[1].mood) }}><div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: theme.palette.mode === 'dark' ? common.white : theme.palette.primary.main }}></div>{showNames && <Typography variant="body2" noWrap={true} align="center" style={{ position: 'absolute', width: 100, display: 'inline-block', left: -45, color: changedKeys.includes(entry[0]) ? theme.palette.primary.red : theme.palette.primary.text }}>{entry[1].name}</Typography>}</div>
        ))}
      </MoodContainerDiv>
    </>

  );
}


MoodOverview.propTypes = {
  user: PropTypes.object,
  showNames: PropTypes.bool
};

export default MoodOverview;
