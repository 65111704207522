import React from "react";

import PropTypes from "prop-types";

import { color, styled, useTheme } from '@mui/system';

import useMediaQuery from '@mui/material/useMediaQuery';

import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from 'react-i18next';

import { auth, database } from "../../../firebase";

import { 
  Button,
  Typography,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  Divider,
  Box,
  FormControl,
  Select,
  TextField,
  InputLabel,
  MenuItem,
  DialogContentText

} from "@mui/material";

const MurcsAppBar = styled(AppBar)(({ theme }) => ({
  position: "relative"
}))

const TitleTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  flex: 1,
}))

const MurcsFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 200,
}))

const SendButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1)
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NoteDialog = (props) => {
  const theme = useTheme();
  const { open, handleClose, roomId, modifyKey, modifyData} = props;
  const { t: translate } = useTranslation();
  
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));



  const [formValid, setFormValid] = React.useState(false);
  const [noteTitle, setNoteTitle] = React.useState('');
  const [noteSubtitle, setNoteSubtitle] = React.useState('');
  const [noteSentiment, setNoteSentiment] = React.useState('positive');
  const [noteAssignee, setNoteAssignee] = React.useState('');

  React.useEffect(() => {

    const initialTitle = (modifyData !== null && modifyData.val() !== null && typeof modifyData.val().title !== 'undefined' &&modifyData.val().title !== null) ? modifyData.val().title : ''
    const initialSubtitle = (modifyData !== null && modifyData.val() !== null && typeof modifyData.val().subtitle !== 'undefined' &&modifyData.val().subtitle !== null) ? modifyData.val().subtitle : ''
    const initialSentiment =  (modifyData !== null && modifyData.val() !== null && typeof modifyData.val().sentiment !== 'undefined' &&modifyData.val().sentiment !== null) ? modifyData.val().sentiment : 'positive'
    const initialAssignee =  (modifyData !== null && modifyData.val() !== null && typeof modifyData.val().assignee !== 'undefined' && modifyData.val().assignee !== null) ? modifyData.val().assignee : ''

    setNoteTitle(initialTitle)
    setNoteSubtitle(initialSubtitle)
    setNoteSentiment(initialSentiment)
    setNoteAssignee(initialAssignee)
  }, [modifyData])



  const handleSubmit = (event) => {
    event.preventDefault()
    console.log("OnSubmit")

    if(!isValid()) return

    if(modifyKey !== null) {
      // MODIFY
      const notesRef =  database.ref("retrospective/notes").child(roomId).child(modifyKey) 

      const currentLocation = (typeof modifyData !== 'undefined' && modifyData !== null && modifyData.val() !== null && modifyData.val().location !== null) ? modifyData.val().location : null

      const updateObject = {
        'title': noteTitle,
        'subtitle': noteSubtitle,
        'sentiment': noteSentiment,
        'updated': Date.now(),
        'assignee': noteAssignee
      }

      if(currentLocation !== null && noteSentiment === 'positive' && currentLocation !== 'positive') {
        updateObject['location'] = 'positive'
      }

      if(currentLocation !== null && noteSentiment === 'negative' && currentLocation === 'positive') {
        updateObject['location'] = 'negative'
      }

      notesRef.update(updateObject)

    } else {
      // CREATE
      const notesRef = database.ref("retrospective/notes").child(roomId).push()  

      const noteObject = {
        creator: auth.currentUser.uid,
        sentiment: noteSentiment,
        location: noteSentiment,
        title: noteTitle,
        subtitle: noteSubtitle,
        created: Date.now(),
        visible: false,
      }
      
      notesRef.set(noteObject)
      console.log(notesRef.key)

    }
    performClose()
  }

  const performClose = (event) => {
    
    
    setNoteTitle('')
    setNoteSubtitle('')
    setNoteAssignee('')
    setNoteSentiment('positive')

    handleClose()
    
  }

  const handleTitleChange = (event) => {
    setNoteTitle(event.target.value)
  }

  const handleSubtitleChange = (event) => {
    setNoteSubtitle(event.target.value)
  }

  const handleSentimentChange = (event) => {
    setNoteSentiment(event.target.value)
  }

  const handleAssigneeChange = (event) => {
    setNoteAssignee(event.target.value)
  }

  React.useEffect(() => {
    setFormValid(isValid())
  }, [noteTitle, noteSentiment])

  const isValid = () => {
    return typeof noteTitle !== 'undefined' && noteTitle.length !== 0 && noteTitle.trim().length !== 0 && noteSentiment !== null
  }

  const showAssigneeField = () => {
    return (typeof modifyData !== 'undefined' && modifyData !== null && modifyData.val() !== null && modifyData.val().location !== null && (modifyData.val().location === 'optimize' || modifyData.val().location === 'check'))
  }


  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={performClose} TransitionComponent={Transition}>
    
      <MurcsAppBar >
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={performClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <TitleTypography variant="h6">
            {translate('retro.noteDialog.title')}
          </TitleTypography>
        </Toolbar>
      </MurcsAppBar>
      <Box>
      <DialogContentText style={{padding: theme.spacing(2)}}>
      {translate('retro.noteDialog.description')}
      </DialogContentText>
      
      <form onSubmit={handleSubmit}>
        <List>
          <ListItem>
            <MurcsFormControl
            sx={{ 
              '& label.Mui-focused': {
                color: theme.palette.primary.dark
              }
            }}>
              <InputLabel id="sentiment-select-label">{translate('retro.noteDialog.sentiment')}</InputLabel>
              <Select
                label={translate('retro.noteDialog.sentiment')}
                labelId="sentiment-select-label"
                id="sentiment-select"
                value={noteSentiment}
                onChange={handleSentimentChange}
              >
                <MenuItem value="positive">{translate('retro.noteDialog.sentimentPositive')}</MenuItem>
                <MenuItem value="negative">{translate('retro.noteDialog.sentimentNegative')}</MenuItem>
                
              </Select>
            </MurcsFormControl>
          </ListItem>
          <ListItem>
            <TextField
              id="standard-full-width"
              name="note-title"
              label={translate('retro.noteDialog.noteTitle')}
              placeholder={translate('retro.noteDialog.noteTitlePlaceholder')}
              value={noteTitle}
              helperText=""
              multiline
              fullWidth
              rowsMin={1}
              margin="normal"
              autoComplete='off'
              onChange={handleTitleChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ 
                '& label.Mui-focused': {
                  color: theme.palette.primary.dark
                }
              }}
            />
          </ListItem>

          <ListItem>
            <TextField
              id="standard-full-width"
              label={translate('retro.noteDialog.noteSubtitle')}
              name="note-subtitle"
              placeholder={translate('retro.noteDialog.noteSubtitlePlaceholder')}
              value={noteSubtitle}
              helperText=""
              multiline
              fullWidth
              rowsMin={1}
              margin="normal"
              autoComplete='off'
              onChange={handleSubtitleChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ 
                '& label.Mui-focused': {
                  color: theme.palette.primary.dark
                }
              }}
            />
          </ListItem>
          
          {showAssigneeField() && <ListItem>
            <TextField
              autoFocus
              id="standard-full-width"
              name="note-assignee"
              label={translate('retro.noteDialog.assignee')}
              placeholder={translate('retro.noteDialog.assigneePlaceholder')}
              value={noteAssignee}
              helperText=""
              fullWidth
              margin="normal"
              onChange={handleAssigneeChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ 
                '& label.Mui-focused': {
                  color: theme.palette.primary.dark
                }
              }}
            />
          </ListItem>}
          
        
          <Divider />
          <ListItem>
            <SendButton variant="contained" color="primary" type="submit" disabled={!formValid} >{translate('retro.noteDialog.saveNoteButtonTitle')}</SendButton>
          </ListItem>
        </List>
      </form>
      </Box>
      
    </Dialog>
  );
}


NoteDialog.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default NoteDialog;
