const ChangelogEntries = [
    {
        version: "1.0.0",
        date: Date.now(), //TODO tatsächliches Datum eintragen
        changes: [
            'changelog.version100.menuInteractionEnhanced',
            'changelog.version100.roomManagementImproved',
            'changelog.version100.deleteRoomFeature',
            'changelog.version100.darkmode',
            'changelog.version100.languageSwitching',
            'changelog.version100.accessibilitySupport',
            'changelog.version100.customValuesInput',
            'changelog.version100.splitViewPlanning',
            'changelog.version100.autofocusOnAssignee',
        ]
    },
    {
        version: "0.2.0",
        date: "2024-02-28",
        changes: [
            'changelog.version020.dependenciesUpdated',
            'changelog.version020.searchFunctionalityAdded',
            'changelog.version020.supportPageEnhanced',
        ]
    }
];

export default ChangelogEntries;
