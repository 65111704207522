import React from "react";

import { styled } from '@mui/system';

import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography'


const RootGrid = styled(Grid)(({ theme }) => ({
  background: 'linear-gradient(-25deg, ' + theme.palette.primary.main + ' 30%, ' + theme.palette.primary.dark + ' 90%)',
  boxShadow: '0 3px 5px 2px rgba(6, 55, 115, .3)',
  color: 'white',
  height: 200,
}))

const MurcsPaper = styled(Paper)(({ theme }) => ({
  padding: 20
}))

const MurcsGrid = styled(Grid)(({ theme }) => ({
  marginTop: 100,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
}))

const NotFoundPage = () => {
  const { t: translate } = useTranslation();

  return (
    <RootGrid container justifyContent="center" alignItems="center" spacing={3} >
      <MurcsGrid item xs={12} sm={8}>

        <MurcsPaper>
          <Typography variant="h1">{translate('notFound.errorCode')}</Typography>
          <Typography variant="h2">{translate('notFound.title')}</Typography>

          <Typography variant="h3">{translate('notFound.description')}</Typography>
        </MurcsPaper>
      </MurcsGrid>
    </RootGrid>
  );



}

export default NotFoundPage;
