import React from "react";
import { styled } from '@mui/system';
import { Input, InputAdornment } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

const SearchBarInput = styled(Input)(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderRadius: theme.spacing(2),
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
    width: '40%',
    maxWidth: '300px',
    backgroundColor: theme.palette.primary.searchBarBackground,
}));

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: ""
    };
  }

  handleChange = (event) => {
    const searchTerm = event.target.value;
    this.setState({ searchTerm });
    this.props.onChange(searchTerm);
  };

  render() {
    return (
      <SearchBarInput
        type="text"
        value={this.state.searchTerm}
        onChange={this.handleChange}
        endAdornment= {
            <InputAdornment position="end">
              <SearchIcon color="secondary"/>
            </InputAdornment>
        }
        disableUnderline
      />
    );
  }
}

export default SearchBar;
