import React from "react";

import PropTypes from "prop-types";
import { styled } from '@mui/system';

import AddIcon from '@mui/icons-material/Add';

import PlanningCreateDialog from '../PlanningCreateDialog'
import PlanningRoomList from '../PlanningRoomList'

import { useTranslation, Trans } from 'react-i18next';

import { 
  Typography,
  Fab,
  Grid,
  Paper,
  useTheme
} from "@mui/material";

const RootDiv = styled('div')({
  flexGrow: 1,
})

const ContainerGrid = styled(Grid)(({theme}) => ({
  padding: theme.spacing(2),
  width: '100%',
  margin: 0
}))

const MurcsFab = styled(Fab)(({theme}) => ({
  position: 'fixed',
  bottom: theme.spacing(8),
  right: theme.spacing(2),
  zIndex: 100
}))

const MurcsPaper = styled(Paper)(({theme}) => ({
  padding: theme.spacing(2),
    color: theme.palette.text.secondary,
}))

const PlanningPage = (props) => {
  const { t: translate } = useTranslation();  const theme = useTheme();
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);

  const handleClickOpen = () => {
    setCreateDialogOpen(true);
  };

  const handleClose = () => {
    setCreateDialogOpen(false);
  };

  return (
    <React.Fragment>
     
      <RootDiv >
        <ContainerGrid container spacing={3}>
          <Grid item xs={12}>
              <Typography variant="h2" sx={{ color: theme.palette.primary.light }}>
                {translate('planning.title')}
              </Typography> 
          </Grid>
          <Grid item xs={12} sm={4}>
            <MurcsPaper >
              <Typography variant="h6" sx={{ color: theme.palette.primary.veryLight }} paragraph>
                {translate('planning.descriptionHeading')}
              </Typography>
              <Typography variant="body1" color="textPrimary" paragraph>
                <Trans i18nKey="planning.description.part1" components={{em: <em/>}} />
              </Typography>
              <Typography variant="body1" color="textPrimary" paragraph>
                <Trans i18nKey="planning.description.part2"/>
              </Typography>
              <Typography variant="body1" color="textPrimary">
                <Trans i18nKey="planning.description.part3" components={{em: <em/>}} />
              </Typography>
            </MurcsPaper>
          </Grid>
          <Grid item  xs={12} sm={8}>
            <MurcsPaper >
              <PlanningRoomList />
            </MurcsPaper>
          </Grid>
        
        </ContainerGrid>
      </RootDiv>
      <MurcsFab variant="extended" aria-label="Add" color="primary" onClick={handleClickOpen}>
      <AddIcon />
      {translate('planning.newRoomButtonTitle')}
      </MurcsFab>
      <PlanningCreateDialog open={createDialogOpen} handleClose={handleClose}/>
      
    </React.Fragment>
   
  );
}


PlanningPage.propTypes = {
  user: PropTypes.object
};

export default PlanningPage;
