import React from "react";

import PropTypes from "prop-types";

import { styled, useTheme } from '@mui/system';

import useMediaQuery from '@mui/material/useMediaQuery';

import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';

import { database } from "../../../firebase";

import {
  Button,
  Typography,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  Divider,
  Box,
  DialogContentText,

  TextField

} from "@mui/material";


const MurcsAppBar = styled(AppBar)({
  position: "relative"
})
const TitleTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  flex: 1,
}))
const SendButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1)
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MilestoneDialog = (props) => {
  const theme = useTheme();
  const { open, handleClose, roomId, modifyKey, modifyData } = props;
  const { t: translate } = useTranslation();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [formValid, setFormValid] = React.useState(false);
  const [milestoneTitle, setMilestoneTitle] = React.useState('');
  const [milestoneSubtitle, setMilestoneSubtitle] = React.useState('');

  const handleSubmit = (event) => {
    event.preventDefault()
    console.log("OnSubmit")

    if (!isValid()) return

    const milestoneRef = modifyKey !== null ? database.ref("retrospective/milestones").child(roomId).child(modifyKey) : database.ref("retrospective/milestones").child(roomId).push()

    const milestoneObject = {
      title: milestoneTitle,
      subtitle: milestoneSubtitle,
      created: Date.now()
    }


    milestoneRef.set(milestoneObject)
    console.log(milestoneRef.key)

    setMilestoneTitle('')
    setMilestoneSubtitle('')

    handleClose()
  }

  const handleTitleChange = (event) => {
    setMilestoneTitle(event.target.value)
  }

  const handleSubtitleChange = (event) => {
    setMilestoneSubtitle(event.target.value)
  }

  React.useEffect(() => {
    const initialTitle = (modifyData !== null && modifyData.title !== null) ? modifyData.title : ''
    const initialSubtitle = (modifyData !== null && modifyData.subtitle !== null) ? modifyData.subtitle : ''

    setMilestoneTitle(initialTitle)
    setMilestoneSubtitle(initialSubtitle)
  }, [modifyData])



  React.useEffect(() => {
    setFormValid(isValid())
  }, [milestoneTitle])

  const isValid = () => {
    return milestoneTitle.length !== 0 && milestoneTitle.trim().length !== 0
  }


  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} TransitionComponent={Transition}>

      <MurcsAppBar>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <TitleTypography variant="h6">
            {translate('retro.milestoneDialog.title')}
          </TitleTypography>
        </Toolbar>
      </MurcsAppBar>
      <Box>
        <DialogContentText style={{ padding: theme.spacing(2) }}>
        {translate('retro.milestoneDialog.description')}
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <List>
            <ListItem>
              <TextField
                id="standard-full-width"
                name="milestone-title"
                label={translate('retro.milestoneDialog.milestoneName')}
                placeholder={translate('retro.milestoneDialog.milestoneNamePlaceholder')}
                value={milestoneTitle}
                helperText=""
                multiline
                fullWidth
                rowsMin={1}
                margin="normal"
                onChange={handleTitleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ 
                  '& label.Mui-focused': {
                    color: theme.palette.primary.dark
                  }
                }}
              />
            </ListItem>

            <ListItem>
              <TextField
                id="standard-full-width"
                name="milestone-subtitle"
                label={translate('retro.milestoneDialog.milestoneSubtitle')}
                placeholder={translate('retro.milestoneDialog.milestoneSubtitlePlaceholder')}
                value={milestoneSubtitle}
                helperText=""
                multiline
                fullWidth
                rowsMin={1}
                margin="normal"
                onChange={handleSubtitleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ 
                  '& label.Mui-focused': {
                    color: theme.palette.primary.dark
                  }
                }}
              />
            </ListItem>


            <Divider />
            <ListItem>
              <SendButton variant="contained" color="primary" type="submit" disabled={!formValid}>{translate('retro.milestoneDialog.saveMileStoneButtonTitle')}</SendButton>
            </ListItem>
          </List>
        </form>
      </Box>

    </Dialog>
  );
}


MilestoneDialog.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default MilestoneDialog;
