import React from "react";

import { loadCSS } from 'fg-loadcss';
import { useList } from 'react-firebase-hooks/database';

import PropTypes from "prop-types";

import { auth, database } from "../../../firebase";

import { styled, useTheme } from '@mui/system';

import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';
import PersonAddSharpIcon from '@mui/icons-material/PersonAddSharp';
import PersonAddDisabledSharpIcon from '@mui/icons-material/PersonAddDisabledSharp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { child, ref } from 'firebase/database';


import {
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  CircularProgress,
  Popover,
  Icon,
  Card,
  CardHeader,
  CardContent,
  CardActions
} from "@mui/material";
import NoteDialog from "../NoteDialog/NoteDialog";

const LoadRootDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > * + *': {
    marginLeft: theme.spacing(2),
  },
}))

const MurcsCircularProgress = styled(CircularProgress)({
  margin: 'auto',
})

const MurcsTypography = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2),
}))

const ExpandIconButton = styled(IconButton)(({ theme }) => ({
  transform: 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

const TypographyBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}))

const PopoverButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.veryLight, 
  ':hover': {
    backgroundColor: theme.palette.primary.blueHover
  } 
}))

const NoteListItem = (props) => {
  const theme = useTheme();
  const { roomId, note, onEditClicked } = props;
  const { t: translate } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const popover = open ? 'simple-popover' : undefined;

  const isColorWeaknessAssistanceSwitchOn = localStorage.getItem('colorWeaknessAssistance') === 'true';

  const positive = {
    backgroundColor: theme.palette.primary.greenBackground
  }
  const negative = {
    backgroundColor: theme.palette.primary.redBackground
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleEditClick = (event) => {
    onEditClicked(note.key, note)
  }


  const handleVoteUpButtonClick = (event) => {
    database.ref("retrospective/notes").child(roomId).child(note.key).child('votes').child(auth.currentUser.uid).set(true)
  }

  const handleVoteDownButtonClick = (event) => {
    database.ref("retrospective/notes").child(roomId).child(note.key).child('votes').child(auth.currentUser.uid).set(null)
  }

  const handlePinToBoard = (event) => {
    database.ref("retrospective/notes").child(roomId).child(note.key).update({
      'visible': true,
      "pinned": Date.now()
    })
  }

  const handleUnpinFromBoard = (event) => {
    database.ref("retrospective/notes").child(roomId).child(note.key).update({
      'visible': false,
      "pinned": Date.now()
    })
  }

  const handleOnDeleteClick = (event) => {
    database.ref("retrospective/notes").child(roomId).child(note.key).set(null)
  }

  const showEditButton = () => {
    return note.val().creator === auth.currentUser.uid
  }

  const showPinButton = () => {
    return !note.val().visible
  }

  const showUnpinButton = () => {
    return note.val().visible && note.val().creator === auth.currentUser.uid
  }

  const showVoteUpButton = () => {
    if (!note.val().visible || note.val().creator === auth.currentUser.uid) return false
    if (typeof note.val().votes === 'undefined' || note.val().votes === null) return true
    return (typeof note.val().votes !== 'undefined' && note.val().votes !== null && !Object.keys(note.val().votes).includes(auth.currentUser.uid))
  }

  const showVoteDownButton = () => {
    return note.val().visible && note.val().creator !== auth.currentUser.uid && (typeof note.val().votes !== 'undefined' && note.val().votes !== null && Object.keys(note.val().votes).includes(auth.currentUser.uid))
  }

  const showDeleteButton = () => {
    return !note.val().visible
  }

  const showMoreMenuButton = () => {
    return showDeleteButton()
  }

  const getCorrectedGridSize = init => {
    const textSize = (note.val().title !== null ? note.val().title.length : 0) + (note.val().subtitle !== null ? note.val().subtitle.length : 0)
    if (textSize > 60) {
      switch (init) {
        case 6: return 6;
        case 4: return 8;
        case 3: return 6;
        case 2: return 4;
        case 1: return 2;
      }
    }
    return init
  }

  return (
    <Grid key={note.key} item xs={getCorrectedGridSize(6)} sm={getCorrectedGridSize(4)} md={getCorrectedGridSize(3)} lg={getCorrectedGridSize(2)} xl={getCorrectedGridSize(1)} style={note}>
      <Card style={note.val().sentiment === 'positive' ? positive : negative}>
        { isColorWeaknessAssistanceSwitchOn ?
          <CardHeader
            action={
              <IconButton disabled>
                {note.val().sentiment === 'positive' ? <SentimentVerySatisfiedIcon/> : <SentimentVeryDissatisfiedIcon/>}
              </IconButton>
            }
            sx={{ paddingBottom: 0 }}
          />
        : null }
        <CardContent>
          <Typography variant="h6" align="center">{note.val().title}</Typography>
          <Typography variant="body1" align="center">{note.val().subtitle}</Typography>
        </CardContent>
        <CardActions disableSpacing>
          {showPinButton() && <IconButton variant="text" onClick={handlePinToBoard}><Icon className="fas fa-thumbtack" color="primary" /></IconButton>}
          {showUnpinButton() && <IconButton variant="text" onClick={handleUnpinFromBoard}><Icon className="fas fa-thumbtack" color="disabled" /></IconButton>}
          {showVoteUpButton() && <IconButton variant="text" onClick={handleVoteUpButtonClick}><PersonAddSharpIcon color="primary" /></IconButton>}
          {showVoteDownButton() && <IconButton variant="text" onClick={handleVoteDownButtonClick}><PersonAddDisabledSharpIcon color="primary" /></IconButton>}
          {showEditButton() && <IconButton variant="text" onClick={handleEditClick}><EditIcon /></IconButton>}
          {showMoreMenuButton() && <ExpandIconButton onClick={handleClick}><MoreVertIcon /></ExpandIconButton>}
        </CardActions>
      </Card>
      <Popover
        id={popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <TypographyBox style={{ padding: theme.spacing(2) }}>
          {showDeleteButton() && <PopoverButton variant="text" onClick={handleOnDeleteClick}>{translate('retro.noteList.deleteButtonTitle')}</PopoverButton>}
        </TypographyBox>
      </Popover>
    </Grid>
  )
}

const NotesList = (props) => {
  const { roomId, visible, add, limitToUid, limitToLocation } = props;
  const { t: translate } = useTranslation();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [modifyKey, setModifyKey] = React.useState(null);
  const [modifyData, setModifyData] = React.useState(null);
  const [notes, loading, error] = useList(child(ref(database), "retrospective/notes/" + roomId))
  const theme = useTheme();

  const handleClickOpen = (event) => {
    setDialogOpen(true)
  }

  const handleClose = (event) => {
    setModifyKey(null)
    setModifyKey(null)
    setDialogOpen(false)
  }

  const handleEditClicked = (noteKey, data) => {

    console.log("Edit")
    setModifyKey(noteKey)
    setModifyData(data)

    setDialogOpen(true)
  }

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
  }, []);




  return (

    <>

      {error && <strong>{translate('retro.noteList.errorFetchNotes')}</strong>}
      {loading && <LoadRootDiv><MurcsCircularProgress /></LoadRootDiv>}

      <Grid container spacing={2}>

        {typeof notes !== 'undefined' && notes !== null &&
          notes
            .filter(note => typeof limitToLocation === 'undefined' || limitToLocation === null || note.val().location === limitToLocation)
            .filter(note => typeof visible === 'undefined' || visible === null || note.val().visible === visible)
            .filter(note => typeof limitToUid === 'undefined' || limitToUid === null || note.val().creator === limitToUid)
            .length === 0 && <MurcsTypography variat="body2"><em>{translate('retro.noteList.noNotes')}</em></MurcsTypography>
        }


        {typeof notes !== 'undefined' && notes !== null &&
          notes
            .filter(note => typeof limitToLocation === 'undefined' || limitToLocation === null || note.val().location === limitToLocation)
            .filter(note => typeof visible === 'undefined' || visible === null || note.val().visible === visible)
            .filter(note => typeof limitToUid === 'undefined' || limitToUid === null || note.val().creator === limitToUid)
            .sort((a, b) => (a.val().sentiment < b.val().sentiment) ? 1 : (a.val().sentiment > b.val().sentiment) ? -1 : (typeof a.val().pinned !== 'undefined' && typeof b.val().pinned !== 'undefined' ? a.val().pinned - b.val().pinned : 0))
            .map(note => (
              <NoteListItem key={note.key} noteKey={note.key} roomId={roomId} note={note} onEditClicked={handleEditClicked} />
            )
            )}

        {add && <Grid item xs={12}>
          <Button variant="outlined" sx={{ color: theme.palette.primary.veryLight, borderColor: theme.palette.primary.light, ':hover': {backgroundColor: theme.palette.primary.hoverBackground}}} onClick={handleClickOpen}><AddIcon />{translate('retro.noteList.addNoteButtonTitle')}</Button>
        </Grid>}
      </Grid>

      <NoteDialog open={dialogOpen} handleClose={handleClose} roomId={roomId} modifyKey={modifyKey} modifyData={modifyData} />

    </>

  );
}



NotesList.propTypes = {
  user: PropTypes.object,

};

export default NotesList;
