import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { styled } from '@mui/system';

import { useTranslation } from 'react-i18next';

import {
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  Button,
  useTheme
} from "@mui/material";

import { ColorModeContext } from "../helper/color-context";


const RootContainerGrid = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  width: '100%',
  margin: 0
}))

const RoomTitleTypography = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  textAlign: "center"
}))

const locales = {
  en: { nativeName: 'English' },
  de: { nativeName: 'Deutsch' }
};
const PreferencesFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: '12px', 
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.mode === 'dark' ? theme.palette.primary.veryLight : null,
    '& + .MuiSwitch-track': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.light : null,
    },
  },
}))

const PreferencesPage = (props) => {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  const { t: translate, i18n } = useTranslation();
  const [isColorWeaknessAssistanceSwitchOn, setIsColorWeaknessAssistanceSwitchOn] = useState(() => {
    return localStorage.getItem('colorWeaknessAssistance') === 'true';
  })

  useEffect(() => {
    localStorage.setItem('colorWeaknessAssistance', isColorWeaknessAssistanceSwitchOn);
  }, [isColorWeaknessAssistanceSwitchOn])

  const handleColorWeaknessAssistanceSwitchToggle = () => {
    setIsColorWeaknessAssistanceSwitchOn(prevState => !prevState);
  }

  return (

    <RootContainerGrid container justifyContent="center" alignItems="center" spacing={3} >
      <Grid xs={12} item>
        <RoomTitleTypography variant="h2" sx={{ color: theme.palette.primary.light }}>{translate('preferences.title')}</RoomTitleTypography>
      </Grid>
      <Grid item>
        {translate('preferences.lightMode')}
        <PreferencesFormControlLabel 
          control={<Switch checked={theme.palette.mode === 'dark'} onChange={colorMode.toggleColorMode} />}
        />
        {translate('preferences.darkMode')}
      </Grid>
      
      <Grid xs={12} item textAlign="center">
        <Typography sx={{ color: theme.palette.primary.veryLight }}>{translate('preferences.colorWeaknessAssistance')}</Typography>
        {translate('preferences.off')}
        <PreferencesFormControlLabel 
          control={<Switch checked={isColorWeaknessAssistanceSwitchOn} onChange={handleColorWeaknessAssistanceSwitchToggle} />}
        />
        {translate('preferences.on')}
      </Grid>

      <Grid xs={12} item textAlign="center">
        {Object.keys(locales).map((locale) => (
          <Button key={locale} style={{ fontWeight: i18n.resolvedLanguage === locale ? 'bold' : 'normal', color: i18n.resolvedLanguage === locale ? theme.palette.primary.veryLight : theme.palette.primary.light }} type="submit" onClick={() => i18n.changeLanguage(locale)}>
            {locales[locale].nativeName}
          </Button>
        ))}
      </Grid>
    </RootContainerGrid>

  );
}



PreferencesPage.propTypes = {
  user: PropTypes.object
};

export default PreferencesPage;
