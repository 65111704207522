import React from "react";

import PropTypes from "prop-types";


import { useObject } from 'react-firebase-hooks/database';
import { auth, database } from "../../../firebase";


import { Link } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { child, ref } from 'firebase/database';
import { styled, useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch

} from "@mui/material";

import MilestoneList from '../MilestoneList'
import MoodOverview from '../MoodOverview'
import BoardNotesList from '../BoardNotesList'

const ContainerGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  margin: 0,
}))

const ContainerGrid2 = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(4),
 
}))

const RootDiv = styled('div')({
  flexGrow: 1,
})

const BoardHeadlineTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}))

const CellBox = styled(Box)(({ theme }) => ({
  border: '1px solid #CCCCCC',
  borderRadius: 5,
  minHeight: 200,
  height: '100%',
  padding: theme.spacing(2),
}))

const RoomContent = (props) => {
  const theme = useTheme();
  const { roomId, room } = props;
  const { t: translate } = useTranslation();

  const roomVal = (typeof room !== 'undefined') ? room.val() : {}

  const [descriptionEditMode, setDescriptionEditMode] = React.useState(false)
  const [showNamesInMoodBoard, setShowNamesInMoodBoard] = React.useState(true)
  const [pendingDescription, setPendingDescription] = React.useState('')
  const [participants] = useObject(child(ref(database), "retrospective/participants/" + roomId))
  const [roomData] = useObject(child(ref(database), "retrospective/roomdata/" + roomId))

  const participantsVal = (typeof participants !== 'undefined' || participants === null) ? participants.val() : {}
  const roomDataVal = (typeof roomData !== 'undefined' || roomData === null) ? roomData.val() : {}

  const handleDescriptionGoToEditMode = (event) => {
    setPendingDescription(roomDataVal !== null && roomDataVal.description !== null ? roomDataVal.description : "")
    setDescriptionEditMode(true)
  }

  const handleDescriptionChange = (event) => {
    setPendingDescription(event.target.value)
  }

  const handleDescriptionSaveClick = (event) => {
    database.ref("retrospective/roomdata").child(roomId).child("description").set(pendingDescription)
    setDescriptionEditMode(false)
  }

  const handleShowMoodSwitchChanged = (event) => {
    setShowNamesInMoodBoard(showNamesInMoodBoard => !showNamesInMoodBoard)
  }


  return (
    <React.Fragment>
      <RootDiv >
        <ContainerGrid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Button variant="contained" color="primary" style={{ float: "right" }} component={Link} to={"/retrospective/".concat(roomId).concat("/participate")}>{translate('retro.roomContent.participateButtonTitle')}</Button>
            <Typography variant="h2" sx={{ color: theme.palette.primary.light }}>
              {roomVal.name}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2"><strong>{translate('retro.roomContent.connectedUsers')} ({typeof participantsVal !== 'undefined' && participantsVal !== null ? Object.keys(participantsVal).length : 0}): </strong>
              {typeof participantsVal !== 'undefined' && participantsVal !== null && Object.values(participantsVal).map((participant, index) => ((index !== 0 ? ", " : "") + participant.name))}
            </Typography>

          </Grid>

          <Grid item xs={12}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="infopanel-content"
                id="infopanel-header"

              >
                <Typography variant="h5">{translate('retro.roomContent.additionalInformation')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  {!descriptionEditMode && <Typography variant="body1" style={{ marginBottom: theme.spacing(1) }}>{roomDataVal !== null && roomDataVal.description !== null ? roomDataVal.description : <em>{translate('retro.roomContent.noDescription')}</em>}</Typography>}
                  {!descriptionEditMode && !roomVal !== null && roomVal.creator === auth.currentUser.uid && <Button variant="outlined" sx={{ color: theme.palette.primary.veryLight, borderColor: theme.palette.primary.veryLight, ':hover': {backgroundColor: theme.palette.primary.subtleHoverBackground} }} onClick={handleDescriptionGoToEditMode}>{translate('retro.roomContent.editDescriptionButtonTitle')}</Button>}
                  {descriptionEditMode && <form>
                    <TextField
                      id="description-edit"
                      label={translate('retro.roomContent.descriptionLabel')}
                      multiline
                      fullWidth
                      rowsMin={4}
                      defaultValue={roomDataVal !== null && roomDataVal.description !== null ? roomDataVal.description : ""}
                      variant="outlined"
                      onChange={handleDescriptionChange}
                      sx={{ 
                        marginBottom: theme.spacing(1),
                        '& label.Mui-focused': {
                          color: theme.palette.primary.veryLight
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: theme.palette.primary.light
                          },
                        },
                      }}
                    />
                    <Button variant="contained" color="primary" onClick={handleDescriptionSaveClick}>{translate('retro.roomContent.save')}</Button>
                  </form>}

                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" style={{ marginBottom: theme.spacing(2) }}>{translate('retro.roomContent.milestones')}</Typography>

            <MilestoneList roomId={roomId} />

          </Grid>

          <ContainerGrid2 item container xs={12} sm={12} spacing={1} justifyContent="center">

            <Grid item xs={12} sm={12} md={6} >
              <CellBox>
                <BoardHeadlineTypography variant="h5" >{translate('retro.roomContent.positive')}</BoardHeadlineTypography>

                <Grid item container xs={12}>
                  <BoardNotesList roomId={roomId} visible={true} add={false} limitToLocation="positive" participants={participantsVal} />
                </Grid>
              </CellBox>
            </Grid>

            <Grid item xs={12} sm={12} md={6} >
              <CellBox>
                <BoardHeadlineTypography variant="h5" >{translate('retro.roomContent.negative')}</BoardHeadlineTypography>

                <Grid item container xs={12}>
                  <BoardNotesList roomId={roomId} visible={true} add={false} limitToLocation="negative" participants={participantsVal} />
                </Grid>
              </CellBox>
            </Grid>

            <Grid item xs={12} sm={12} md={6} >
              <CellBox>
                <BoardHeadlineTypography variant="h5" >{translate('retro.roomContent.optimize')}</BoardHeadlineTypography>

                <Grid item container xs={12}>
                  <BoardNotesList roomId={roomId} visible={true} add={false} limitToLocation="optimize" participants={participantsVal} />
                </Grid>
              </CellBox>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <CellBox>
                <BoardHeadlineTypography variant="h5" >{translate('retro.roomContent.check')}</BoardHeadlineTypography>

                <Grid item container xs={12}>
                  <BoardNotesList roomId={roomId} visible={true} add={false} limitToLocation="check" participants={participantsVal} />
                </Grid>
              </CellBox>
            </Grid>
          </ContainerGrid2>

          <Grid item xs={12}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"

              >
                <Typography variant="h5">{translate('retro.roomContent.mood')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <MoodOverview data={participantsVal} showNames={showNamesInMoodBoard} />
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12}>
            <Accordion defaultExpanded={false}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="settingspanel-content"
                id="settingspanel-header"

              >
                <Typography variant="h5">{translate('retro.roomContent.displayOptions')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch 
                        sx={{ '& .MuiSwitch-switchBase.Mui-checked': {
                          color: theme.palette.mode === 'dark' ? theme.palette.primary.veryLight : null,
                          '& + .MuiSwitch-track': {
                            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.light : '#000',
                          },
                        },}}
                        checked={showNamesInMoodBoard} 
                        onChange={handleShowMoodSwitchChanged} name="showNamesOnMoodBoard" />}
                      label={translate('retro.roomContent.displayOptionsDescription')}
                    />

                  </FormGroup>

                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

        </ContainerGrid>
      </RootDiv>
    </React.Fragment>

  )
}

RoomContent.propTypes = {
  user: PropTypes.object
};

export default RoomContent;
