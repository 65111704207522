import { format } from 'date-fns';
import { de, enUS } from 'date-fns/locale';

const dateFormatConfig = {
  short: {
    en: 'MMMM d yyyy',
    de: 'd. MMMM yyyy',
  },
  long: {
    en: 'EEEE, MMMM d yyyy hh:mm:ss a',
    de: 'EEEE, d. MMMM yyyy HH:mm:ss',
  }
};

const formatDate = (date, locale, type) => {
  const localeObject = locale === 'de' ? de : enUS;
  const dateFormat = dateFormatConfig[type][locale] || dateFormatConfig[type].en;

  return format(date, dateFormat, { locale: localeObject });
};

export const formatDateShort = (date, locale) => formatDate(date, locale, 'short');
export const formatDateLong = (date, locale) => formatDate(date, locale, 'long');
