import React from "react";

import PropTypes from "prop-types";

import { useTranslation, Trans } from 'react-i18next';

import { styled } from '@mui/system';


import { 
  Typography,
  Grid ,
  Paper,
  Fab,
  useTheme
} from "@mui/material";

import RetrospectiveCreateDialog from '../RetrospectiveCreateDialog'
import RoomList from '../RoomList'


import AddIcon from '@mui/icons-material/Add';


const RootDiv = styled('div')({
  flexGrow: 1,
})

const ContainerGrid = styled(Grid)(({theme}) => ({
  padding: theme.spacing(2),
  width: '100%',
  margin: 0
}))

const MurcsPaper = styled(Paper)(({theme}) => ({
  padding: theme.spacing(2),
    color: theme.palette.text.secondary,
}))

const MurcsFab = styled(Fab)(({theme}) => ({
  position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(2),
    zIndex: 100
}))


const RetrospectivePage = (props) => {
  const { user } = props;
  const theme = useTheme();  const { t: translate } = useTranslation();

  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);

  const handleClickOpen = () => {
    setCreateDialogOpen(true);
  };

  const handleClose = () => {
    setCreateDialogOpen(false);
  };

  return (
    <React.Fragment>
    
    <RootDiv >
      <ContainerGrid container spacing={3}>
        <Grid item xs={12}>
            <Typography variant="h2" sx={{ color: theme.palette.primary.light }}>
              {translate('retro.title')}
            </Typography> 
        </Grid>
        <Grid item xs={12} sm={4}>
          <MurcsPaper>
            <Typography variant="h6" sx={{ color: theme.palette.primary.veryLight }} paragraph>
              {translate('retro.descriptionHeading')}
            </Typography>
            <Typography variant="body1" color="textPrimary" paragraph>
              {translate('retro.description.part1')}
            </Typography>
            <Typography variant="body1" color="textPrimary" paragraph>
              <Trans i18nKey="retro.description.part2" components={{em: <em/>}} />
            </Typography>
            
          </MurcsPaper>
        </Grid>
        <Grid item  xs={12} sm={8}>
          <MurcsPaper >
            <RoomList user={user} />
          </MurcsPaper>
        </Grid>
      
      </ContainerGrid>
    </RootDiv>
    <MurcsFab variant="extended" aria-label="Add" color="primary" onClick={handleClickOpen}>
    <AddIcon />
    {translate('retro.newRoomButtonTitle')}
    </MurcsFab>
    <RetrospectiveCreateDialog open={createDialogOpen} handleClose={handleClose}/>
    
  </React.Fragment>
  );
}



RetrospectivePage.propTypes = {
  user: PropTypes.object
};

export default RetrospectivePage;
