import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { useObject } from 'react-firebase-hooks/database';
import { auth, database } from "../../../firebase";

import { Link } from "react-router-dom";
import { styled, useTheme } from '@mui/system';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import RoomCards from '../RoomCards';
import ParticipateContent from "../ParticipateContent/ParticipateContent";

import { useTranslation } from 'react-i18next';

import { mapPlanningValue } from '../PlanningValueMapper';
import { child, ref, remove } from 'firebase/database';

import {
  Button,
  Typography,
  Grid,
  Box,
  Paper
} from "@mui/material";

const ItemGrid = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

const RootDiv = styled('div')({
  flexGrow: 1,
});

const ActionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const MurcsPaper2 = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const MurcsPaper = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

const ContainerGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  margin: 0,
}));

const RoomContent = (props) => {
  const theme = useTheme();
  const { roomId, room } = props;
  const [isSplit, setIsSplit] = useState(false);  const { t: translate } = useTranslation();

  const [participants] = useObject(child(ref(database), `planning/participants/${roomId}`));

  const roomVal = room ? room.val() : {};
  const { participantsVal, pickedValue } = useParticipantValues(participants);
  const canPerformActions = roomVal.creator === auth.currentUser.uid || (roomVal.users && roomVal.users[auth.currentUser.uid] === "owner");

  const toggleSplit = () => {
    setIsSplit(prevState => !prevState);
  };

  const handleRevealAll = () => {
    if (!participantsVal) return;
    const newData = {};

    Object.keys(participantsVal).forEach((participant) => {
      newData[participant.concat('/visible')] = true;
    });

    database.ref("planning/participants").child(roomId).update(newData);
  };

  const handleCloseAll = () => {
    if (!participantsVal) return;
    const newData = {};

    Object.keys(participantsVal).forEach((participant) => {
      newData[participant.concat('/visible')] = false;
    });

    database.ref("planning/participants").child(roomId).update(newData);
  };

  const handleResetAll = () => {
    if (!participantsVal) return;
    const newData = {};

    Object.keys(participantsVal).forEach((participant) => {
      newData[participant.concat('/visible')] = false;
      newData[participant.concat('/value')] = -1;
    });

    database.ref("planning/participants").child(roomId).update(newData);
  };

  const deleteRoom = (roomId) => {
    remove(ref(database, `planning/publicrooms/${roomId}`))
      .then(() => {
        console.log(`Room ${roomId} successfully deleted from publicrooms`);
      })
      .catch((error) => {
        console.error('Error deleting room:', error.message);
      });
  };

  return (
    <React.Fragment>
      <RootDiv>
        <ContainerGrid container spacing={3} alignItems="center">
          <Grid item xs>
            <Typography variant="h2" sx={{ color: theme.palette.primary.light }}>
              {roomVal.name}
            </Typography>
          </Grid>
          <Grid item>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Button variant="contained" color="primary" sx={{ marginRight: "8px" }} onClick={toggleSplit}>
                {isSplit ? translate('planning.roomContent.closeSplitScreen') : translate('planning.roomContent.openSplitScreen')}
              </Button>
              {!isSplit && (
                <Button variant="contained" color="primary" component={Link} to={`/planning/${roomId}/participate`}>
                  {translate('planning.roomContent.participateButtonTitle')}
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            {isSplit ? (
              // layout for split screen
              <Grid container>
                <Grid item sm={12} md={6}>
                  <RoomCards roomId={roomId} participants={participants} pickedValue={pickedValue} canPerformActions={canPerformActions} roomVal={roomVal} isSplitScreen={isSplit} />
                  <Box sx={{ marginTop: "30px" }}>
                    <RoomContentMainPart roomId={roomId} room={room} toggleSplit={toggleSplit} participants={participants} />
                  </Box>
                </Grid>
                <Grid item sm={12} md={6}>
                  <ParticipateContent roomId={roomId} room={room} isSplitScreen={isSplit} />
                </Grid>
              </Grid>
            ) : (
              // layout for normal screen
              <Grid item xs={12}>
                <RoomCards roomId={roomId} participants={participants} pickedValue={pickedValue} roomVal={roomVal} canPerformActions={canPerformActions} isSplitScreen={isSplit} />
                <Box sx={{ marginTop: "30px" }}>
                  <RoomContentMainPart roomId={roomId} room={room} toggleSplit={toggleSplit} participants={participants} />
                </Box>
              </Grid>
            )}
          </Grid>

          {canPerformActions ? (
          <Grid item xs={12}>
              <MurcsPaper2>
              <ActionButton variant="contained" color="primary" onClick={handleRevealAll} >{translate('planning.roomContent.revealAllButtonTitle')}</ActionButton>
                <ActionButton variant="contained" color="primary" onClick={handleCloseAll} >{translate('planning.roomContent.closeAllButtonTitle')}</ActionButton>
                <ActionButton variant="contained" color="primary" onClick={handleResetAll} >{translate('planning.roomContent.resetAllButtonTitle')}</ActionButton>
              </MurcsPaper2>
            </Grid>) : null }

          {canPerformActions ? (
            <Grid item xs={12}>
            <Button variant="contained" color="error" onClick={() => deleteRoom(roomId)} component={Link} to={"/planning"}>
                {translate('planning.roomContent.deleteRoomButtonTitle')}
            </Button> 
          </Grid>) : null } 

        </ContainerGrid>
      </RootDiv>
    </React.Fragment>
  );
};

RoomContent.propTypes = {
  roomId: PropTypes.string.isRequired,
  room: PropTypes.object.isRequired,
  isSplitScreen: PropTypes.bool.isRequired,
};

export default RoomContent;

const RoomContentMainPart = (props) => {
  const theme = useTheme();
  const { participants, room } = props;
  const { participantsVal, pickedValuesArray, pickedValue } = useParticipantValues(participants);
  const { t: translate } = useTranslation();

  const roomVal = room ? room.val() : {};

  const connectedUsers = participantsVal ? Object.keys(participantsVal).length : 0;
  const readyUsersArray = participantsVal ? Object.values(participantsVal).map((v) => v.value).filter(value => value === -1) : [];
  
  const agreementRatio = pickedValuesArray.length > 0 ? parseFloat(pickedValuesArray.filter(v => v === pickedValue).length) / parseFloat(pickedValuesArray.length) : 0;
                      
  const valueAgreed = {
    backgroundColor: theme.palette.primary.greenBackground,
  };
  
  const valueYellow = {
    backgroundColor: theme.palette.primary.yellowBackground,
  };

  return (
    <Grid item xs={12}>
      <MurcsPaper> 
        <Grid container style={{ margin: theme.spacing(-1) }}>
          <ItemGrid item xs={6} sm={3}>
            <Typography variant="body1" align="center">{translate('planning.roomContent.connectedUsers')}</Typography>
            <Typography variant="h2" align="center">{connectedUsers}</Typography>
          </ItemGrid>
          <ItemGrid item xs={6} sm={3}>
            <Typography variant="body1" align="center">{translate('planning.roomContent.everybodyReady')}</Typography>
            <Typography variant="h2" align="center">{(readyUsersArray.length === 0) ? <CheckIcon style={{ fontSize: '0.85em' }}/> : <CloseIcon style={{ fontSize: '0.85em' }}/>}</Typography>
          </ItemGrid>
          <ItemGrid item xs={6} sm={3} sx={[agreementRatio === 1 ? valueAgreed : agreementRatio > 0 ? valueYellow : "" ]}>
            <Typography variant="body1" align="center">{translate('planning.roomContent.chosenValue')}</Typography>
            <Typography variant="h2" align="center" sx={{ color: theme.palette.text.primary }}>{mapPlanningValue(pickedValue, roomVal.values)}</Typography>
          </ItemGrid>
          <ItemGrid item xs={6} sm={3} sx={[agreementRatio === 1 ? valueAgreed : agreementRatio > 0 ? valueYellow : "" ]}>
            <Typography variant="body1" align="center">{translate('planning.roomContent.agreement')}</Typography>
            <Typography variant="h2" align="center">{isNaN(agreementRatio) ? "..." : (agreementRatio * 100.0).toFixed(0)}</Typography>
          </ItemGrid>
        </Grid>
      </MurcsPaper>
    </Grid>
  );
};

RoomContentMainPart.propTypes = {
  roomId: PropTypes.string.isRequired,
};

function useParticipantValues(participants) {
  const [participantValues, setParticipantValues] = useState({
    participantsVal: {},
    visibleValuesArray: [],
    pickedValuesArray: [],
    pickedValue: null,
  });

  useEffect(() => {
    if (!participants) {
      setParticipantValues({
        participantsVal: {},
        visibleValuesArray: [],
        pickedValuesArray: [],
        pickedValue: null,
      });
      return;
    }

    const participantsVal = participants.val() || {};
    const visibleValuesArray = Object.values(participantsVal)
      .filter(v => v.visible)
      .map(v => v.value);
    const pickedValuesArray = visibleValuesArray.filter(v => v !== -1);
    const pickedValue = pickedValuesArray.length > 0
      ? pickedValuesArray
          .sort((a, b) =>
            pickedValuesArray.filter(v => v === a).length -
            pickedValuesArray.filter(v => v === b).length)
          .pop()
      : null;

    setParticipantValues({
      participantsVal,
      visibleValuesArray,
      pickedValuesArray,
      pickedValue,
    });
  }, [participants]);

  return participantValues;
}
