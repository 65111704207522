import React from "react";

import PropTypes from "prop-types";

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/system';

import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Divider,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Avatar,
  ListItemButton,
} from "@mui/material";

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import ContactIcon from '@mui/icons-material/Phone';
import ScheduleIcon from '@mui/icons-material/Schedule';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToApp from '@mui/icons-material/ExitToApp';

import ValidUserHelper from "../helper/ValidUserHelper";

import { common } from "@mui/material/colors";

const drawerWidth = 240;

const DrawerHeaderDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

const MurcsDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
}))

const ProfileBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  minWidth: 200,
  backgroundColor: theme.palette.primary.subtleBackground,
}))

const ProfileNameTypography = styled(Typography)(({
  marginTop: 10,
}));

const LargeAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
}))

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
}))

const StyledMenu = styled(Menu)({
  paddingTop: 0,
  elevation: 0,
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  }
});

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

const NoUnderlineTypography = styled(Typography)(({ theme }) => ({
  textDecoration: 'none',
  color: common.white
}));


const Bar = (props) => {

  // Functions
  const { onLogout, user, userData } = props;

  // Properties
  const { title, destination } = props;
  const theme = props.theme;
  const [open, setOpen] = React.useState(false);
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = React.useState(null);
  const profileMenuOpen = Boolean(profileMenuAnchorEl);
  const { t: translate } = useTranslation();

  const appBar = {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }

  const appBarShift = {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }

  const handleDrawerToggle = () => {
    setOpen(!open)
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileMenu = (event) => {
    setProfileMenuAnchorEl(event.currentTarget);
  }

  const handleProfileMenuClose = (event) => {
    setProfileMenuAnchorEl(null)
  }


  const getUserName = () => {
    return userData !== null && typeof userData.public === 'object' && typeof userData.public.displayName !== 'undefined' && userData.public.displayName !== null ? userData.public.displayName : ""
  }

  const getProfileImageUrl = () => {
    return userData !== null && typeof userData.public === 'object' && typeof userData.public.photoURL !== 'undefined' && userData.public.photoURL !== null ? userData.public.photoURL : ""
  }

  const hasProfileImageUrl = () => {
    return userData !== null && typeof userData.public === 'object' && typeof userData.public.photoURL !== 'undefined' && userData.public.photoURL.includes("http")
  }

  const handleLogout = (event) => {
    handleProfileMenuClose()
    onLogout()
  }

  const isValidUser = ValidUserHelper.isValidUser(user);

  return (
    <React.Fragment>
      <AppBar position="static"
        sx={{
          ...appBar,
          ...(open && appBarShift), backgroundColor: theme.palette.primary.dark
        }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => { handleDrawerToggle() }}>
            <MenuIcon />
          </IconButton>
          <Box display="flex" flexGrow={1}>
            <NoUnderlineTypography color="inherit" variant="h6" component={Link} to="/">
              MURCS
            </NoUnderlineTypography>
            <Typography color="inherit" variant="h6" noWrap={true}>
              &nbsp;&#9656;&nbsp;
            </Typography>
            <NoUnderlineTypography color="inherit" variant="h6" component={Link} to={"/".concat(destination)} noWrap={true}>
              {title}
            </NoUnderlineTypography>
          </Box>
          {user !== null && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleProfileMenu}
                color="inherit">
                {hasProfileImageUrl() ? <SmallAvatar alt={getUserName()} src={getProfileImageUrl()} /> : <AccountCircleIcon />}

              </IconButton>
              <StyledMenu
                id="menu-appbar"
                anchorEl={profileMenuAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={profileMenuOpen}
                onClose={handleProfileMenuClose}
                MenuListProps={{
                  disablePadding: true,
                }}
              >

                <ProfileBox align="center">
                  <LargeAvatar alt={getUserName()} src={getProfileImageUrl()} />
                  <ProfileNameTypography variant="body1"><strong>{translate('profile.greetingTitle')} {getUserName()}</strong></ProfileNameTypography>
                </ProfileBox>
                <Divider />
                {isValidUser ? (
                <StyledMenuItem component={Link} to="/account" onClick={handleProfileMenuClose}>
                  <ListItemIcon>
                    <AccountBoxIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={translate('profile.accountTitle')} />
                </StyledMenuItem>
                ) : null }
                {isValidUser ? (
                <StyledMenuItem component={Link} to="/preferences" onClick={handleProfileMenuClose} divider={<Divider />}>
                  <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={translate('profile.preferencesTitle')} />
                </StyledMenuItem>
                ) : null }
                <StyledMenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <ExitToApp fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={translate('profile.logoutTitle')} />
                </StyledMenuItem>
              </StyledMenu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <MurcsDrawer
        anchor="left"
        open={open}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: {
            width: drawerWidth
          }
        }}
      >
        <DrawerHeaderDiv>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>

        </DrawerHeaderDiv>
        <Divider />
        <List sx={{ paddingTop: 0 }}>

          <ListItemButton key="home" component={Link} to="/" onClick={() => handleDrawerClose()} selected={(destination === "home")}>
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary={translate('menu.home')} />
          </ListItemButton>

          <Divider />
          {isValidUser ? (
            <Box>
              <ListItemButton key="planning" component={Link} to="/planning" onClick={() => handleDrawerClose()} selected={(destination === "planning")}>
                <ListItemIcon><ScheduleIcon /></ListItemIcon>
                <ListItemText primary={translate('menu.planning')} />
              </ListItemButton>

              <ListItemButton key="retrospective" component={Link} to="/retrospective" onClick={() => handleDrawerClose()} selected={(destination === "retrospective")}>
                <ListItemIcon><QuestionAnswerIcon /></ListItemIcon>
                <ListItemText primary={translate('menu.retrospective')} />
              </ListItemButton>

              <Divider />
            </Box>
          ) : null }

          <ListItemButton key="contact" component={Link} to="/contact" onClick={() => handleDrawerClose()} selected={(destination === "contact")}>
            <ListItemIcon><ContactIcon /></ListItemIcon>
            <ListItemText primary={translate('menu.contact')} />
          </ListItemButton>

        </List>
      </MurcsDrawer>

    </React.Fragment>
  );
}


Bar.defaultProps = {
  performingAction: false
};

Bar.propTypes = {
  // Properties
  user: PropTypes.object,
  userData: PropTypes.object,
  // Events
  onLogout: PropTypes.func.isRequired,
};

export default Bar;
