import React from "react";

import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from '@mui/system';

import useMediaQuery from '@mui/material/useMediaQuery';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';

import { auth, database} from "../../../firebase";

import { 
  Button,
  Typography,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  Divider,
  Box,
  FormControlLabel,
  FormLabel,
  Checkbox,
  DialogContentText,
  TextField
} from "@mui/material";

const MurcsAppBar = styled(AppBar)(({ theme }) => ({
  position: "relative"
}))

const TitleTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  flex: 1,
}))

const SendButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1)
}))

const OverlineTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2)
}))

const CheckboxFormLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: "0.8em"
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PlanningCreateDialog = (props) => {
  let navigate = useNavigate();
  const theme = useTheme();
  const { open, handleClose } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t: translate } = useTranslation();

  const [formValid, setFormValid] = React.useState(false);
  const [roomName, setRoomName] = React.useState('');
  const [roomPassword, setRoomPassword] = React.useState('');
  const [roomPublic, setRoomPublic] = React.useState(true);

  const initialRoomValues = [
    { id: 'zero', label: '0', selected: true },
    { id: 'pointfive', label: '0.5', selected: true },
    { id: 'one', label: '1', selected: true },
    { id: 'two', label: '2', selected: true },
    { id: 'three', label: '3', selected: true },
    { id: 'five', label: '5', selected: true },
    { id: 'eight', label: '8', selected: false },
    { id: 'thirteen', label: '13', selected: false },
    { id: 'twenty', label: '20', selected: false },
    { id: 'fourty', label: '40', selected: false },
    { id: 'hundred', label: '100', selected: false }
  ];
  const [roomValues, setRoomValues] = React.useState(initialRoomValues);
  const [showTextField, setShowTextField] = React.useState(false);
  const [textFieldValue, setTextFieldValue] = React.useState('');

  var md5 = require('md5');

  const handleSubmit = (event) => {
    event.preventDefault()
    console.log("OnSubmit")

    if(!isValid()) return;

    const newRef = database.ref("planning/rooms").push();

    const selectedValues = roomValues.filter(value => value.selected);

    const roomObject = {
      name: roomName,
      creator: auth.currentUser.uid,
      values: selectedValues,
      password: (roomPassword.length === 0) ? "" : md5(roomPassword),
      public: roomPublic,
      created: Date.now(),
    }

    newRef.set(roomObject);
    console.log(newRef.key);

    if(roomPublic) {
      const publicRef = database.ref(`planning/publicrooms/${newRef.key}`);
      publicRef.set({
        name: roomName,
        creator: auth.currentUser.uid,
        created: Date.now()
      });
    }
    navigate(`/planning/${newRef.key}`);
  }

  const handleRoomNameChange = (event) => {
    setRoomName(event.target.value);
  }

  const handleValuesChange = (event) => {
    console.log(event.target.name);
    setRoomValues({ ...roomValues, [event.target.name]: event.target.checked });
  }

  const handleRoomPasswordChange = (event) => {
    setRoomPassword(event.target.value);
  }

  const handleRoomPublicChange = (event) => {
    setRoomPublic(!roomPublic);
  }

  const handleAddValue = () => {
    setShowTextField(true);
  }

  const normalizeValue = (value) => {
    return parseFloat(value).toString();
  }

  const handleAddCustomValue = () => {
    const normalizedValue = normalizeValue(textFieldValue);
    if (!isNumeric(normalizedValue)) {
      return;
    }
    const newValue = {
      id: `custom-${textFieldValue}`, 
      label: textFieldValue,
      selected: true
    };
    setRoomValues([...roomValues, newValue]);
    setTextFieldValue('');
    setShowTextField(false);
  }

  const isNumeric = (value) => {
    return /^-?\d+(\.\d+)?$/.test(value);
  }

  const handleRemoveValue = (id) => {
    setRoomValues(roomValues.filter(value => value.id !== id));
  }

  const handleValueClick = (valueId) => {
    setRoomValues(roomValues.map(value =>
      value.id === valueId ? { ...value, selected: !value.selected } : value
    ));
  }

  const handleTextFieldChange = (event) => {
    const value = event.target.value;
    setTextFieldValue(value.replace(',', '.'));
  }

  React.useEffect(() => {
    setFormValid(isValid());
  }, [roomName, roomValues, roomPassword]);

  const isValid = () => {
    return roomName.trim().length > 0 && roomValues.filter(value => value.selected).length >= 2 && roomPassword.trim().length > 0;
  }

  const valuesError = roomValues.filter(value => value.selected).length < 2;
  const alreadyExistsWarning = roomValues.some(value => normalizeValue(value.label) === normalizeValue(textFieldValue));
  const onlyNumericValuesAllowedWarning = !isNumeric(textFieldValue) && textFieldValue.trim() !== "";

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} TransitionComponent={Transition}>
      <MurcsAppBar>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <TitleTypography variant="h6">
            {translate('planning.createDialog.title')}
          </TitleTypography>
        </Toolbar>
      </MurcsAppBar>
      <Box>
        <DialogContentText style={{padding: theme.spacing(2)}}>
          {translate('planning.createDialog.description')}
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <List>
            <ListItem>
              <OverlineTypography variant="overline">
                {translate('planning.createDialog.roomData')}
              </OverlineTypography>
            </ListItem>
            <ListItem>
              <TextField
                id="standard-full-width"
                label={translate('planning.createDialog.roomName')}
                placeholder={translate('planning.createDialog.roomNamePlaceholder')}
                name="planning-name"
                helperText=""
                fullWidth
                margin="normal"
                onChange={handleRoomNameChange}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ 
                  '& label.Mui-focused': {
                    color: theme.palette.primary.dark
                  }
                }}
              />
            </ListItem>
            <ListItem>
              <OverlineTypography variant="overline">{translate('planning.createDialog.pickableValues')}</OverlineTypography>
            </ListItem>
            <ListItem>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {roomValues.map(value => (
                  <Button
                    key={value.id}
                    variant={value.selected ? "contained" : "outlined"}
                    onClick={() => handleValueClick(value.id)}
                    onChange={handleValuesChange}
                    sx={{ borderRadius: '50px', fontSize: '0.75rem', minWidth: '0', height: '24px', mr: 1, color: value.selected ? 'common.white' : theme.palette.mode === 'dark' ? 'secondary.main' : 'primary.main', backgroundColor: value.selected ? 'primary.main' : 'transparent', borderColor: value.selected ? 'primary.main' : theme.palette.mode === 'dark' ? 'secondary.main' : 'primary.main'}}
                    endIcon={value.id.startsWith('custom-') ? <CloseIcon fontSize="small" sx={{ padding: '4px' }} onClick={(e) => { e.stopPropagation(); handleRemoveValue(value.id); }} /> : null}
                  >
                    {value.label}
                  </Button>
                ))}
                <IconButton 
                  onClick={handleAddValue} 
                  color="primary" 
                  sx={{ 
                    borderRadius: '50px',
                    padding: '4px', 
                    fontSize: '0.75rem', 
                  }}
                >
                  <AddIcon sx={{ fontSize: '1rem', color:  theme.palette.mode === 'dark' ? 'secondary.main' : 'primary.main'}} /> 
                </IconButton>
              </Box>
            </ListItem>
            <ListItem>
              <Typography
                sx={{ color: 'error.main', fontSize: '0.75rem' }}
                hidden={!valuesError}
              >
                {translate('planning.createDialog.valuesError')}
              </Typography>
            </ListItem>
            <ListItem>
              {showTextField && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <TextField
                    size="small"
                    variant="filled"
                    autoFocus
                    placeholder={translate('planning.createDialog.customValue')}
                    value={textFieldValue}
                    onChange={handleTextFieldChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleAddCustomValue();
                    }}
                    sx={{ 
                      '& .MuiFilledInput-input':{
                        paddingTop:theme.spacing(1)
                      }
                    }}
                  />
                  <Button onClick={handleAddCustomValue} variant="contained" disabled={alreadyExistsWarning || onlyNumericValuesAllowedWarning || !textFieldValue}>
                  {translate('planning.createDialog.customValueAdd')}
                  </Button>
                </Box>
              )}
            </ListItem>
            <ListItem>
              <Typography
                sx={{ color: 'warning.main', fontSize: '0.75rem', mb: 1 }}
                hidden={!alreadyExistsWarning && !onlyNumericValuesAllowedWarning}
              >
                {alreadyExistsWarning ? "This value already exists!" : onlyNumericValuesAllowedWarning ? "Only numeric values are allowed!" : null}
              </Typography>
            </ListItem>
            <Divider />
            <ListItem>
              <OverlineTypography variant="overline">
                {translate('planning.createDialog.privacySettings')}
              </OverlineTypography>
            </ListItem>
            <ListItem>
              <TextField
                id="standard-full-width"
                label={translate('planning.createDialog.password')}
                placeholder={translate('planning.createDialog.passwordPlaceholder')}
                helperText=""
                fullWidth
                type="password"
                margin="normal"
                onChange={handleRoomPasswordChange}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ 
                  '& label.Mui-focused': {
                    color: theme.palette.primary.dark
                  }
                }}
              />
            </ListItem>
            <ListItem>
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={state.checkedB}
                    // onChange={handleChange}                    
                    name="showInDirectory"
                    sx={{
                      '& .MuiSvgIcon-root': {
                        color: theme.palette.mode === 'dark' ? 'secondary.main' : 'primary.main',
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        color: theme.palette.mode === 'dark' ? 'secondary.main' : 'primary.main',
                      },
                    }}
                    checked={roomPublic}
                    onChange={handleRoomPublicChange}
                  />
                }
                label={translate('planning.createDialog.listRoomCheckbox')}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <SendButton variant="contained" color="primary" type="submit" disabled={!formValid}>{translate('planning.createDialog.createButtonTitle')}</SendButton>
            </ListItem>
          </List>
        </form>
      </Box>
    </Dialog>
  );
}

PlanningCreateDialog.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default PlanningCreateDialog;
