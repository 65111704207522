import React from "react";

import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/system';

import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';

import { auth, database } from "../../../firebase";

import { 
  Button,
  Typography,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  Divider,
  Box,
  FormControlLabel,
  Checkbox,
  DialogContentText,
  TextField,

} from "@mui/material";


const MurcsAppBar = styled(AppBar)(({ theme }) => ({
  position: "relative"
}))

const TitleTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  flex: 1,
}))

const OverlineTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2)
}))

const SendButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1)
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RetrospectiveCreateDialog = (props) => {
  let navigate = useNavigate();
  const theme = useTheme();
  const { open, handleClose } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t: translate } = useTranslation();

  const [formValid, setFormValid] = React.useState(false);
  const [roomName, setRoomName] = React.useState('');
  const [roomPassword, setRoomPassword] = React.useState('');
  const [roomPublic, setRoomPublic] = React.useState(true);

  var md5 = require('md5');

  const handleSubmit = (event) => {
    event.preventDefault()
    console.log("OnSubmit")

    if(!isValid()) return

    const newRef = database.ref("retrospective/rooms").push()

    const roomObject = {
      name: roomName,
      creator: auth.currentUser.uid,
      password: (roomPassword.length === 0)? "" : md5(roomPassword),
      public: roomPublic,
      created: Date.now(),
      
    }

    newRef.set(roomObject)
    console.log(newRef.key)

    if(roomPublic === true) {

      const publicRef = database.ref("retrospective/publicrooms/".concat(newRef.key))

      publicRef.set({
        name: roomName,
        creator: auth.currentUser.uid,
        created: Date.now()
      })

    }
    

    navigate('/retrospective/'.concat(newRef.key))

  }


  const handleRoomNameChange = (event) => {
    setRoomName(event.target.value)
  }

  const handleRoomPasswordChange = (event) => {
    setRoomPassword(event.target.value)
  }

  const handleRoomPublicChange = (event) => {
    setRoomPublic(!roomPublic)
  }

  React.useEffect(() => {
    setFormValid(isValid())
  }, [roomName, roomPassword])

  const isValid = () => {
    return roomName.length !== 0 && roomName.trim().length !== 0 && roomPassword.length !== 0 && roomPassword.trim().length !== 0
  }

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} TransitionComponent={Transition}>
     
      <MurcsAppBar>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <TitleTypography variant="h6">
            {translate('retro.createRoomDialog.title')}
          </TitleTypography>
        </Toolbar>
      </MurcsAppBar>
      <Box>
      <DialogContentText style={{padding: theme.spacing(2)}}>
      {translate('retro.createRoomDialog.description')}
      </DialogContentText>
      <form onSubmit={handleSubmit}>
        <List>
          <ListItem>
            <OverlineTypography variant="overline" >
            {translate('retro.createRoomDialog.roomData')}
            </OverlineTypography>
          </ListItem>
          <ListItem>
            <TextField
              id="standard-full-width"
              name="retrospective-name"
              label={translate('retro.createRoomDialog.roomName')}
              placeholder={translate('retro.createRoomDialog.roomNamePlaceholder')}
              
              helperText=""
              fullWidth
              margin="normal"
              onChange={handleRoomNameChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ 
                '& label.Mui-focused': {
                  color: theme.palette.primary.dark
                }
              }}
            />
          </ListItem>
          
          <Divider />
          <ListItem>
            <OverlineTypography variant="overline">
            {translate('retro.createRoomDialog.privacySettings')}
            </OverlineTypography>
          </ListItem>
          <ListItem>
          
            <TextField
              id="standard-full-width"
              label={translate('retro.createRoomDialog.password')}
              placeholder={translate('retro.createRoomDialog.passwordPlaceholder')}
              helperText=""
              fullWidth
              type="password"
              margin="normal"
              onChange={handleRoomPasswordChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ 
                '& label.Mui-focused': {
                  color: theme.palette.primary.dark
                }
              }}
            />
          </ListItem>
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  // checked={state.checkedB}
                  // onChange={handleChange}
                  name="showInDirectory"
                  sx={{
                    '& .MuiSvgIcon-root': {
                      color: theme.palette.mode === 'dark' ? 'secondary.main' : 'primary.main',
                    },
                    '&.Mui-checked .MuiSvgIcon-root': {
                      color: theme.palette.mode === 'dark' ? 'secondary.main' : 'primary.main',
                    },
                  }}
                  checked={roomPublic}
                  onChange={handleRoomPublicChange}
                />
              }
              label={translate('retro.createRoomDialog.listRoomCheckbox')}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <SendButton variant="contained" color="primary" type="submit" disabled={!formValid}>{translate('retro.createRoomDialog.createButtonTitle')}</SendButton>
          </ListItem>
        </List>
      </form>
      </Box>
      
    </Dialog>
  );
}


RetrospectiveCreateDialog.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default RetrospectiveCreateDialog;
