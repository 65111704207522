import React from "react";

import PropTypes from "prop-types";

import { styled } from '@mui/system';


import { sortPlanningValue } from '../PlanningValueMapper'

import {
  Button,
  Typography,
  Grid,
  Box,
  useTheme
} from "@mui/material";

const ContainerGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0),
}))

const OptionButton = styled(Button)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.primary.subtleBackground,
  ':hover': {
    backgroundColor: theme.palette.primary.subtleHoverBackground
  },
}))


const OptionListItem = (props) => {
  const { data, onOptionPicked } = props
  const theme = useTheme();
  const handleSelection = (event) => {
    onOptionPicked(data.id)
  }


  return (
    <OptionButton variant="outlined" onClick={handleSelection} elevation={0} disableElevation disableFocusRipple sx={{margin: "4px", color: theme.palette.primary.veryLight, borderColor: theme.palette.primary.light }}>
      <Typography variant="h6">{data.label}</Typography>
    </OptionButton>
  )
}


const OptionList = (props) => {
  const { room, onOptionPicked } = props;

  // const [userData, loading, error] = useList(database.ref("planning/participants").child(roomId).child(auth.currentUser.uid))
  // const [values, loading, error] = useList(database.ref("planning/rooms").child(roomId).child("values"))

  const roomVal = (typeof room !== 'undefined') ? room.val() : {}
  const sortedValues = roomVal.values.sort(sortPlanningValue);

  return (

    <>

      {/* {typeof room !== 'undefined' && room !== null && JSON.stringify(room)} */}

      <Box display="flex" justifyContent="center">
        <Box display="flex" flexWrap="wrap" justifyContent="center" maxWidth="720px" >
          {sortedValues.map((label, index) => (
            <Grid key={index}>
              <OptionListItem onOptionPicked={onOptionPicked} data={label} />
            </Grid>
          ))}
        </Box>
      </Box>
    </>

  );
}


OptionList.propTypes = {
  user: PropTypes.object,

};

export default OptionList;
