import React from "react";

import PropTypes from "prop-types";

import { auth } from "../../firebase";

import { GoogleAuthProvider } from "firebase/auth"

import { styled, useTheme } from '@mui/system';

import { useTranslation, Trans } from 'react-i18next';

import StyledFirebaseAuth from "../helper/StyledFirebaseAuth.tsx";

import { 
  Typography,
  Grid,
} from "@mui/material";

const RootContainerGrid = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  width: '100%',
  margin: 0
}))

const MurcsGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2)
}))

const RoomTitleTypography = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  textAlign: "center"
}))

const NotLoggedInPage = () => {
  const theme = useTheme();
  const { t: translate } = useTranslation();
  
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      GoogleAuthProvider.PROVIDER_ID,
    ],

    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false
    }
  };

  return (
    <RootContainerGrid container justifyContent="center" alignItems="center" spacing={3} >
      <MurcsGrid xs={12} item>
        <RoomTitleTypography variant="h2" sx={{ color: theme.palette.primary.light }}>{translate('notLoggedIn.welcomeTitle')}</RoomTitleTypography>
      </MurcsGrid>  
      
      <MurcsGrid item xs={12} sm={8} textAlign="center">
        <Typography variant="body1">{translate('notLoggedIn.welcomeDescription')}</Typography>
        <Typography variant="body1" sx={{ marginTop: theme.spacing(1) }}>
        <Trans
          i18nKey="notLoggedIn.useWithGoogle"
          components={{
            1: <span style={{ fontWeight: 'bold', color: theme.palette.primary.veryLight }} />,
            3: <span style={{ fontWeight: 'bold', color: theme.palette.primary.veryLight }} />,
            5: <span style={{ fontWeight: 'bold', color: theme.palette.primary.veryLight }} />
          }}
        />
      </Typography>
      </MurcsGrid>

      <MurcsGrid xs={12} item>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth}/>
      </MurcsGrid>
      
  </RootContainerGrid>
  );
}

NotLoggedInPage.propTypes = {
  user: PropTypes.object
};

export default NotLoggedInPage;
