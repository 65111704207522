import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../../firebase';
import ValidUserHelper from '../helper/ValidUserHelper';

const ProtectedRoute = ({ children }) => {
  if (!auth.currentUser || !ValidUserHelper.isValidUser(auth.currentUser)) {
    return <Navigate replace to="/login" />;
  }

  return children;
};

export default ProtectedRoute;