import firebase from "firebase/compat/app";

// import "firebase/compat/analytics";
// import {getAuth } from "firebase/auth";
// import {getFirestore} from "firebase/firestore";
// import {getStorage} from "firebase/storage";
// import {getDatabase} from "firebase/database";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/firestore";
// import "firebase/performance";

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});

// var firebaseConfig = {
//   apiKey: "AIzaSyBLmWQbodiQoay7JE2d-n61gQvWyqPLYU4",
//   authDomain: "murcs-dev.firebaseapp.com",
//   databaseURL: "https://murcs-dev.firebaseio.com",
//   projectId: "murcs-dev",
//   storageBucket: "murcs-dev.appspot.com",
//   messagingSenderId: "598770363003",
//   appId: "1:598770363003:web:58d9c5f5ecda3f366990de",
//   measurementId: "G-B5JWEFHBS5"
// };
// // Initialize Firebase
// firebase.initializeApp(firebaseConfig);

// firebase.analytics();



// export const analytics = firebase.analytics();
// export const base = firebase;
// export const auth = getAuth(app);
// export const firestore = getFirestore(app);
// export const database = getDatabase(app);
// export const storage = getStorage(app);
export const auth = firebase.auth();
export const database = firebase.database();

// export const performance = firebase.performance();
