import React from "react";

import { loadCSS } from 'fg-loadcss';
import { useList } from 'react-firebase-hooks/database';

import PropTypes from "prop-types";

import { auth, database } from "../../../firebase";

import { styled, useTheme } from '@mui/system';

import AddIcon from '@mui/icons-material/Add';
import ThumbUpIcon from '@mui/icons-material/ThumbUpAlt';
import PersonAddSharpIcon from '@mui/icons-material/PersonAddSharp';
import PersonAddDisabledSharpIcon from '@mui/icons-material/PersonAddDisabledSharp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { child, ref } from 'firebase/database';

import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  CircularProgress,
  Popover,
  Icon,
  Card,
  CardHeader,
  CardContent,
  CardActions
} from "@mui/material";
import NoteDialog from "../NoteDialog/NoteDialog";

const LoadRootDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > * + *': {
    marginLeft: theme.spacing(2),
  },
}))

const MurcsCircularProgress = styled(CircularProgress)({
  margin: 'auto',
})
const VotesTypography = styled(Typography)(({ theme }) => ({
  minWidth: 'fit-content'
}))

const MurcsCardActions = styled(CardActions)({
  marginTop: 0,
    paddingTop: 0,
    marginBottom: 0,
    paddingBottom: 0,
    paddingRight: 0,
})

const ExpandIconButton = styled(IconButton)(({ theme }) => ({
  transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
}))

const TypographyBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}))

const ContentTypography = styled(Typography)({
  hyphens: 'auto',
  wordBreak: 'break-word'
})

const RootGrid = styled(Grid)(({ theme }) => ({
  paddingRight: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}))

const PopoverButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.veryLight, 
  ':hover': {
    backgroundColor: theme.palette.primary.blueHover
  } 
}))

const NoteListItem = (props) => {
  const theme = useTheme();
  const { roomId, note, onEditClicked, participants } = props;
  const { t: translate } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const popover = open ? 'simple-popover' : undefined;

  const isColorWeaknessAssistanceSwitchOn = localStorage.getItem('colorWeaknessAssistance') === 'true';

  const positive = {
    backgroundColor: theme.palette.primary.greenBackground,
    borderColor: theme.palette.primary.green,
    borderStyle: 'solid',
    borderWidth: 0,
  }

  const negative = {
    backgroundColor: theme.palette.primary.redBackground,
    borderColor: theme.palette.primary.red,
    borderStyle: 'solid',
    borderWidth: 0,
  }

  const borderThin = {
    borderWidth: 'thin'
  }

  const borderMedium = {
    borderWidth: 'medium'
  }

  const borderThick = {
    borderWidth: 'thick'
  }

  const content = {
    hyphens: 'auto',
    wordBreak: 'break-word'
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleVoteUpButtonClick = (event) => {
    database.ref("retrospective/notes").child(roomId).child(note.key).child('votes').child(auth.currentUser.uid).set(true)
  }

  const handleVoteDownButtonClick = (event) => {
    database.ref("retrospective/notes").child(roomId).child(note.key).child('votes').child(auth.currentUser.uid).set(null)
  }

  const handlePinToBoard = (event) => {
    database.ref("retrospective/notes").child(roomId).child(note.key).update({
      'visible': true,
      "pinned": Date.now()
    })
  }

  const handleUnpinFromBoard = (event) => {
    database.ref("retrospective/notes").child(roomId).child(note.key).update({
      'visible': false,
      "pinned": Date.now()
    })
  }

  const handleToOptimizeClick = (event) => {
    database.ref("retrospective/notes").child(roomId).child(note.key).child('location').set('optimize')
  }

  const handleToCheckClick = (event) => {
    database.ref("retrospective/notes").child(roomId).child(note.key).child('location').set('check')
  }

  const handleToNegativeClick = (event) => {
    database.ref("retrospective/notes").child(roomId).child(note.key).child('location').set('negative')
  }

  const handleSetCheckedClick = (event) => {
    database.ref("retrospective/notes").child(roomId).child(note.key).child('checked').set(true)
  }

  const handleSetUncheckedClick = (event) => {
    database.ref("retrospective/notes").child(roomId).child(note.key).child('checked').set(false)
  }

  const handleEditClick = (event) => {
    handleClose()
    onEditClicked(note.key, note)
  }

  const handleAssigneeClick = (event) => {
    handleClose()
    onEditClicked(note.key, note)
  }

  const showEditButton = () => {
    return note.val().creator === auth.currentUser.uid
  }

  const showAssigneeButton = () => {
    return note.val().location === 'optimize' || note.val().location === 'check'
  }


  const showPinButton = () => {
    return !note.val().visible
  }

  const showUnpinButton = () => {
    return note.val().visible && note.val().creator === auth.currentUser.uid
  }

  const showVoteUpButton = () => {
    if (!note.val().visible || note.val().creator === auth.currentUser.uid) return false
    if (typeof note.val().votes === 'undefined' || note.val().votes === null) return true
    return (typeof note.val().votes !== 'undefined' && note.val().votes !== null && !Object.keys(note.val().votes).includes(auth.currentUser.uid))
  }

  const showVoteDownButton = () => {
    return note.val().visible && note.val().creator !== auth.currentUser.uid && (typeof note.val().votes !== 'undefined' && note.val().votes !== null && Object.keys(note.val().votes).includes(auth.currentUser.uid))
  }

  const showNegativeButton = () => {
    return note.val().visible && note.val().location === 'optimize'
  }

  const showOptimizeButton = () => {
    return (note.val().visible && note.val().location === 'negative') || (note.val().visible && note.val().location === 'check')
  }

  const showCheckButton = () => {
    return note.val().visible && note.val().location === 'optimize'
  }

  const showSetCheckedButton = () => {
    return note.val().visible && note.val().location === 'check' && (typeof note.val().checked === 'undefined' || note.val().checked === false)
  }

  const showSetUncheckedButton = () => {
    return note.val().visible && note.val().location === 'check' && note.val().checked === true
  }

  const showMoreMenuButton = () => {
    return true
  }

  const getVoteCount = () => {
    if (typeof note.val().votes === 'undefined' || note.val().votes === null) return 0;
    return Object.keys(note.val().votes).length
  }

  function between(x, min, max) {
    return x >= min && x <= max;
  }

  console.log(participants)

  const getUserNameForUid = (uid) => {

    return typeof participants !== 'undefined' && participants !== null && typeof participants[uid] !== 'undefined' && typeof participants[uid].name !== 'undefined' ? participants[uid].name : "?????"

  }

  const getCorrectedGridSize = init => {
    const textSize = (note.val().title !== null ? note.val().title.length : 0) + (note.val().subtitle !== null ? note.val().subtitle.length : 0)
    if (textSize > 70) {
      switch (init) {
        case 6: return 6;
        case 4: return 8;
        case 3: return 6;
        case 2: return 4;
        case 1: return 2;
      }
    }
    return init
  }

  return (
    <RootGrid item key={note.key} xs={getCorrectedGridSize(6)} sm={getCorrectedGridSize(6)} md={getCorrectedGridSize(4)} lg={getCorrectedGridSize(3)} xl={getCorrectedGridSize(2)}>
      <Card sx={{
        ...(note.val().sentiment === 'positive' && positive),
        ...(note.val().sentiment === 'negative' && negative),
        ...(between(getVoteCount(), 1, 5) && borderThin),
        ...(between(getVoteCount(), 6, 9) && borderMedium),
        ...(getVoteCount() >= 10 && borderThick)}}>
        { isColorWeaknessAssistanceSwitchOn ?
          <CardHeader
            action={
              <IconButton disabled>
                {note.val().sentiment === 'positive' ? <SentimentVerySatisfiedIcon/> : <SentimentVeryDissatisfiedIcon/>}
              </IconButton>
            }
            sx={{ paddingBottom: 0 }}
          />
        : null }
        <CardContent style={content}>
          {typeof note.val().checked !== 'undefined' && note.val().checked === true && <CheckIcon className={CheckIcon} />}
          <ContentTypography variant="h6" align="center"  >{note.val().title}</ContentTypography>
          <ContentTypography variant="body1" align="center" >{note.val().subtitle}</ContentTypography>
          <ContentTypography variant="h6" color="textPrimary"   align="right">{note.val().assignee}</ContentTypography>


        </CardContent>

        <MurcsCardActions disableSpacing>
          <VotesTypography variant="h6">&nbsp;{getVoteCount()}<ThumbUpIcon style={{ fontSize: '0.9em',  paddingTop: theme.spacing(0.25)}} /></VotesTypography>
          {showVoteUpButton() && <IconButton variant="text" onClick={handleVoteUpButtonClick}><PersonAddSharpIcon color="primary" /></IconButton>}
          {showVoteDownButton() && <IconButton variant="text" onClick={handleVoteDownButtonClick}><PersonAddDisabledSharpIcon color="primary" /></IconButton>}
          {showMoreMenuButton() && <ExpandIconButton
            onClick={handleClick} >
            <MoreVertIcon />
          </ExpandIconButton>}
        </MurcsCardActions>

      </Card>
      <Popover
        id={popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <TypographyBox style={{ padding: theme.spacing(2) }}>
          <Typography variant="body0">{translate('retro.boardNotes.reporter')} {getUserNameForUid(note.val().creator)}</Typography>
        </TypographyBox>
        <TypographyBox style={{ padding: theme.spacing(2) }}>
          {showPinButton() && <PopoverButton variant="text" onClick={handlePinToBoard}><Icon className="fas fa-thumbtack" fontSize="small"/>{translate('retro.boardNotes.pin')}</PopoverButton>}
          {showUnpinButton() && <PopoverButton variant="text" onClick={handleUnpinFromBoard}><Icon className="fas fa-thumbtack" fontSize="small" />{translate('retro.boardNotes.unpin')}</PopoverButton>}
          {showEditButton() && <PopoverButton variant="text" onClick={handleEditClick}><EditIcon fontSize="small"/>{translate('retro.boardNotes.edit')}</PopoverButton>}
          {showNegativeButton() && <PopoverButton variant="text" onClick={handleToNegativeClick}>{translate('retro.boardNotes.toNegative')}</PopoverButton>}
          {showOptimizeButton() && <PopoverButton variant="text" onClick={handleToOptimizeClick}>{translate('retro.boardNotes.toOptimize')}</PopoverButton>}
          {showCheckButton() && <PopoverButton variant="text" onClick={handleToCheckClick}>{translate('retro.boardNotes.toCheck')}</PopoverButton>}
          {showAssigneeButton() && <PopoverButton variant="text" onClick={handleAssigneeClick}>{translate('retro.boardNotes.setAssignee')}</PopoverButton>}
          {showSetCheckedButton() && <PopoverButton variant="text" onClick={handleSetCheckedClick}>{translate('retro.boardNotes.markAsChecked')}</PopoverButton>}
          {showSetUncheckedButton() && <PopoverButton variant="text" onClick={handleSetUncheckedClick}>{translate('retro.boardNotes.markAsUnchecked')}</PopoverButton>}
        </TypographyBox>
      </Popover>

    </RootGrid>
  )
}

const BoardNotesList = (props) => {
  const theme = useTheme();
  const { roomId, visible, add, limitToUid, limitToLocation, participants } = props;
  const { t: translate } = useTranslation();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [modifyKey, setModifyKey] = React.useState(null);
  const [modifyData, setModifyData] = React.useState(null);
  const [notes, loading, error] = useList(child(ref(database), "retrospective/notes/" + roomId))

  const handleClickOpen = (event) => {
    setDialogOpen(true)
  }

  const handleClose = (event) => {
    setModifyKey(null)
    setModifyKey(null)
    setDialogOpen(false)
  }

  const handleEditClicked = (noteKey, data) => {

    console.log("Edit")
    setModifyKey(noteKey)
    setModifyData(data)

    setDialogOpen(true)
  }

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
  }, []);




  return (

    <>

      {error && <strong>{translate('retro.boardNotes.errorMilestones')}</strong>}
      {loading && <LoadRootDiv><MurcsCircularProgress /></LoadRootDiv>}

      <Grid container spacing={1} style={{margin:theme.spacing(-1)}}>

        {typeof notes !== 'undefined' && notes !== null &&
          notes
            .filter(note => typeof limitToLocation === 'undefined' || limitToLocation === null || note.val().location === limitToLocation)
            .filter(note => typeof visible === 'undefined' || visible === null || note.val().visible === visible)
            .filter(note => typeof limitToUid === 'undefined' || limitToUid === null || note.val().creator === limitToUid)
            // .sort((a,b) => (a.val().sentiment < b.val().sentiment ) ? 1 : (a.val().sentiment > b.val().sentiment ) ? -1 : (typeof a.val().pinned !== 'undefined' && typeof b.val().pinned !== 'undefined' ? a.val().pinned-b.val().pinned : 0))
            .sort((a, b) => ((a.val().sentiment < b.val().sentiment) ? 1 : (a.val().sentiment > b.val().sentiment) ? -1 : (typeof a.val().pinned !== 'undefined' ? a.val().pinned : 0) - (typeof b.val().pinned !== 'undefined' ? b.val().pinned : 0)))
            .map(note => (
              <NoteListItem key={note.key} noteKey={note.key} roomId={roomId} note={note} onEditClicked={handleEditClicked} participants={participants} />
            )
            )}

        {add && <Grid item xs={12}>
          <Button variant="outlined" sx={{ color: theme.palette.primary.veryLight, borderColor: theme.palette.primary.veryLight }} onClick={handleClickOpen}><AddIcon />{translate('retro.boardNotes.addNote')}</Button>
        </Grid>}
      </Grid>

      <NoteDialog open={dialogOpen} handleClose={handleClose} roomId={roomId} modifyKey={modifyKey} modifyData={modifyData} />

    </>

  );
}


BoardNotesList.propTypes = {
  user: PropTypes.object,

};

export default BoardNotesList;
