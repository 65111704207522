import React, { useEffect } from 'react';

import CssBaseline from '@mui/material/CssBaseline';

import { auth, database } from "../../firebase";

import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

import { styled } from '@mui/system';

import { Suspense } from 'react';

import { useTranslation } from 'react-i18next';

import {
  CircularProgress
} from "@mui/material"

import { ColorModeContext } from '../helper/color-context';

import Router from "../Router";

import './App.css';

import { common, grey } from '@mui/material/colors';
import chroma from 'chroma-js';


// Farbkonstanten
const COLORS = {
  check24Blue: '#063773',
  lightGrey: '#CCCCCC',
  darkGrey: '#212426',
  darkerGrey: '#171a1c',
  green: '#749c44',
  lightGreen: '#b6e86b',
  darkGreen: '#92bd4a',
  red: '#b85033',
  lightRed: '#e8866b',
  darkRed: '#bf5b42',
  yellow: '#fcf56d'
};

// MATERIAL UI THEME
const getDesignTokens = (mode) => {

  const {
    check24Blue,
    lightGrey,
    darkGrey,
    darkerGrey,
    green,
    lightGreen,
    darkGreen,
    red,
    lightRed,
    darkRed,
    yellow
  } = COLORS;

  const check24BlueShades = {
    veryLight: chroma(check24Blue).set('hsl.l', '+0.25').hex(),
    light: chroma(check24Blue).set('hsl.l', '+0.1').hex(),
    dark: chroma(check24Blue).darken(0.6).hex(), 
  }

  const check24BlueAlpha = {
    dark: chroma(check24Blue).alpha(.4).hex(),
    light: chroma(check24Blue).alpha(.1).hex()
  }
  
  return {
    palette: {
      mode,
      primary: {
        veryLight: mode === 'light' ? check24Blue : check24BlueShades.veryLight,
        light: mode === 'light' ? check24Blue : check24BlueShades.light,
        main: check24Blue, // primary color
        dark: mode === 'light' ? check24Blue : check24BlueShades.dark,
        searchBarBackground: mode === 'light' ? grey[200] : darkGrey,
        subtleIcon: mode === 'light' ? lightGrey : grey[700],
        subtleBackground: mode === 'light' ? grey[200] : darkerGrey, // also footer background
        subtleHoverBackground: mode === 'light' ? grey[300] : grey[900],
        hoverBackground: mode === 'light' ? grey[200] : darkerGrey,
        redHover: mode === 'light' ? red : lightRed,
        blueHover: mode === 'light' ? check24BlueAlpha.light : check24BlueAlpha.dark,
        greenHover: mode === 'light' ? green : lightGreen,
        green: green,
        greenBackground: mode === 'light' ? lightGreen : darkGreen,
        red: red,
        redBackground: mode === 'light' ? lightRed : darkRed,
        yellowBackground: yellow,
        contrastText: common.white,
        badge: mode === 'light' ? check24Blue : check24BlueShades.light
      },
      secondary: {  
        main: mode === 'light' ? grey[600] : grey[400], // secondary color
        contrastText: common.black,
      },
      text: {
        primary: mode === 'light' ? grey[800] : grey[300],
        secondary: mode === 'light' ? grey[700] : grey[500],
      },
      background: {
        default: mode === 'light' ? common.white : grey[900], //main background
        paper: mode === 'light' ? common.white : darkGrey, // card background
      }
    }, 
    typography: {
      h1: {
        color: mode === 'light' ? check24Blue : check24BlueShades.light,
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    }, 
  }
};
  

const MurcsCircularProgress = styled(CircularProgress)(({theme}) => ({
  margin: 'auto',
  marginTop: 200,
}))

const LoadRootDiv =  styled('div')(({theme}) => ({
  display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
}))

function App() {
  const [initialized, setInitialized] = React.useState(false);
  const [user, setUser] = React.useState(auth.currentUser);
  const [userData, setUserData] = React.useState(null);
  const [userDataRef, setUserDataRef] = React.useState(null);
  const [mode, setMode] = React.useState('light');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        localStorage.setItem("mode", mode === 'light' ? 'dark' : 'light' )
        setMode((prevMode) =>
          prevMode === 'light' ? 'dark' : 'light',
        );
      },
    }),
    [],
  );

  useEffect(()=>{
    if( localStorage.getItem("mode")){
     setMode(localStorage.getItem("mode"))
      }
  },[])

  const theme = React.useMemo(() => responsiveFontSizes(createTheme(getDesignTokens(mode)), [mode]));
const { t: translate } = useTranslation();

  const handleLogout = () => {

    if(user) {
      console.log("Logout")

      if(userDataRef !== null) {
        console.log("Unsubscribing from userData")
        userDataRef.off("value")
      }

      auth.signOut().then(() => {
        // Redirect to the homepage
        window.location.href = "/";
      }).catch(error => {
          console.error("Logout error:", error);
      });
    } else {
      console.log("Login")

    }
    
  }

  React.useEffect( () => {
    
    const disposable = auth.onAuthStateChanged(function(user) {
      setUser(user);
      setInitialized(true);

      if(typeof user !== 'undefined' && user !== null) {

        // try to fetch user information object
        database.ref('data/users').child(user.uid).once("value", 
          (snapshot) => {
            if(!snapshot.exists()) {
              const newUser = {
                displayName: typeof user.displayName !== 'undefined' && user.displayName !== null ? user.displayName : translate('account.unknownUser'),
                email: typeof user.email !== 'undefined' && user.email !== null ? user.email : "",
                emailVerified: typeof user.emailVerified !== 'undefined' && user.emailVerified !== null ? user.emailVerified : "",
                phoneNumber: typeof user.phoneNumber !== 'undefined' && user.phoneNumber !== null ? user.phoneNumber : "",
                provider: typeof user.providerData !== 'undefined' && user.providerData !== null && Array.isArray(user.providerData) && user.providerData.length > 0 ? user.providerData.map(userInfo => userInfo.providerId) : translate('account.anonymous'),
                disabled: typeof user.disabled !== 'undefined' && user.disabled !== null ? user.disabled : false,
                isAnonymous: user.isAnonymous,
                creationTime: typeof user.metadata !== 'undefined' && user.metadata !== null && typeof user.metadata.creationTime !== 'undefined' && user.metadata.creationTime !== null ? user.metadata.creationTime : "",
                lastSignInTime: typeof user.metadata !== 'undefined' && user.metadata !== null && typeof user.metadata.lastSignInTime !== 'undefined' && user.metadata.lastSignInTime !== null ? user.metadata.lastSignInTime : "",
                lastVisit: Date.now(),
                public: {
                    displayName: typeof user.displayName !== 'undefined' && user.displayName !== null ? user.displayName : translate('account.unknownUser'),
                    photoURL: typeof user.photoURL !== 'undefined' && user.photoURL !== null ? user.photoURL : ""
        
                }
              }

              snapshot.ref.set(newUser)
            } else {
              if(typeof user.metadata !== 'undefined' && user.metadata !== null && typeof user.metadata.lastSignInTime !== 'undefined' && user.metadata.lastSignInTime !== null) {
                snapshot.ref.update({
                  'lastSignInTime': user.metadata.lastSignInTime,
                  'lastVisit': Date.now()
                });
              }
            }
          },
          (error) => {
            console.error(error.message, error)
          }
        )

      }

    });
    return () => {
      disposable();
    }
  }, [])

  React.useEffect(() => {

    if(user !== null) {

       // listen to userdata object
      const ref = database.ref('data/users').child(user.uid)
      
      setUserDataRef(ref)

      ref.on("value", 
        snapshot => {
          if(snapshot.exists()) {
            setUserData(snapshot.val())
          } else {
            setUserData(null)
          }
          
        },
        error => {
          console.error(error)
        }
      )

    } else {
      // user is not logged in

      setUserData(null)
    }

  }, user)

  return (
    <React.Fragment>
          <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
                {initialized ?<Router
                  user={user}
                  userData={userData}
                  theme={theme}
                  onLogout={() => handleLogout()}        
                  /> : <LoadRootDiv><MurcsCircularProgress /></LoadRootDiv>}
          </ThemeProvider>
        </ColorModeContext.Provider>
    </React.Fragment>
    
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  )
}
