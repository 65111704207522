import React from "react";
import PropTypes from "prop-types";
import { auth, database } from "../../../firebase";
import { styled } from '@mui/system';

import { useTranslation } from 'react-i18next';

import { 
  Button,
  Slider

} from "@mui/material";

const PostButton = styled(Button)(({theme}) => ({
  color: theme.palette.primary.green,
  borderColor: theme.palette.primary.green,
  ':hover': {
    backgroundColor: theme.palette.primary.hoverBackground,
    borderColor: theme.palette.primary.greenHover
  }
}))

const RemoveButton = styled(Button)(({theme}) => ({
  color: theme.palette.primary.red,
  borderColor: theme.palette.primary.red,
  ':hover': {
    backgroundColor: theme.palette.primary.hoverBackground,
    borderColor: theme.palette.primary.redHover
  }
}))


const MoodSliderView = (props) => {
  const { roomId, data } = props;
  const currentValue = React.useRef(3);
  const { t: translate } = useTranslation();
  
  const valuetext = (value)  => {
    currentValue.current = value
    return `${value}`;
  }

  const marks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
  ];

  

  const handleMoodChangeCommitted = event => {
    database.ref("retrospective/participants").child(roomId).child(auth.currentUser.uid).update({ 'mood': currentValue.current})
  }

  const handleMoodShow = event => {
    database.ref("retrospective/participants").child(roomId).child(auth.currentUser.uid).child('moodVisible').set(true)
  }

  const handleMoodHide = event => {
    database.ref("retrospective/participants").child(roomId).child(auth.currentUser.uid).child('moodVisible').set(false)
  }

  const def = data !== null && data.mood !== null ? data.mood : 3.0

  return (
  
    <> 
      <Slider
        defaultValue={def}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-small-steps"
        step={0.1}
        min={1}
        max={5}
        onChangeCommitted={handleMoodChangeCommitted}
       
        valueLabelDisplay="on"
        marks={marks}
        sx={{
          '& .MuiSlider-valueLabel': {
            backgroundColor: '#063773', //main color
            color: '#fff', // white color for the value label text
            borderRadius: 2, 
            fontSize: 12,
          },
          '& .MuiSlider-track': {
            height: 0.01,
          },
          '& .MuiSlider-rail': {
            height: 0.05,
          },
          '& .MuiSlider-thumb': {
            width: 12,
            height: 12,
          }
        }}
      />

      {data !== null && data.moodVisible !== null && data.moodVisible ? <RemoveButton variant="outlined" onClick={handleMoodHide}>{translate('retro.moodSlider.removeButtonTitle')}</RemoveButton> : <PostButton variant="outlined" onClick={handleMoodShow}>{translate('retro.moodSlider.postButtonTitle')}</PostButton>}
      
    </>
    
  );
}


MoodSliderView.propTypes = {
  user: PropTypes.object,

};

export default MoodSliderView;
