import React from "react";

import { useList } from 'react-firebase-hooks/database';

import PropTypes from "prop-types";

import { database } from "../../../firebase";

import { styled, useTheme } from '@mui/system';

import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';

import { ref } from 'firebase/database';

import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Popover

} from "@mui/material";

import MilestoneDialog from '../MilestoneDialog'

const LoadRootDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > * + *': {
    marginLeft: theme.spacing(2),
  },
}))

const MurcsCircularProgress = styled(CircularProgress)({
  margin: 'auto',
})

const MurcsPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
}))

const TypographyBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}))

const PopoverButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.veryLight, 
  ':hover': {
    backgroundColor: theme.palette.primary.blueHover
  } 
}))

const MilestoneListItem = (props) => {
  const { data, milestoneKey, roomId, onEditClicked } = props
  const theme = useTheme();
  const { t: translate } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const popover = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClicked = (event) => {
    handleClose()
    onEditClicked(milestoneKey, data)
  }

  const handleDelete = (event) => {
    handleClose()
    database.ref("retrospective/milestones").child(roomId).child(milestoneKey).set({})
  }

  return (
    <Grid item xs={6} sm={4} md={3} lg={2}>
      <MurcsPaper aria-describedby={popover} onClick={handleClick}>
        <Typography variant="h6" sx={{ wordBreak: 'break-word' }}>{data.title}</Typography>
        <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>{data.subtitle}</Typography>
      </MurcsPaper>
      <Popover
        id={popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <TypographyBox>
          <PopoverButton variant="text" onClick={handleEditClicked}>{translate('retro.milestoneList.editButtonTitle')}</PopoverButton>
          <PopoverButton variant="text" onClick={handleDelete} >{translate('retro.milestoneList.deleteButtonTitle')}</PopoverButton>
        </TypographyBox>
      </Popover>
    </Grid>
  )
}


const MilestoneList = (props) => {
  const theme = useTheme();
  const { roomId } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [modifyKey, setModifyKey] = React.useState(null);
  const [modifyData, setModifyData] = React.useState(null);
  const [milestones, loading, error] = useList(ref(database, "retrospective/milestones/" + roomId))
  const { t: translate } = useTranslation();

  const handleClickOpen = (event) => {
    setDialogOpen(true)
  }

  const handleClose = (event) => {
    setModifyKey(null)
    setModifyKey(null)
    setDialogOpen(false)
  }

  const handleEditClicked = (milestoneKey, data) => {

    console.log("Edit")
    setModifyKey(milestoneKey)
    setModifyData(data)

    setDialogOpen(true)
  }

  return (

    <>

      {error && <strong>{translate('retro.milestoneList.errorFetchMilestones')}</strong>}
      {loading && <LoadRootDiv><MurcsCircularProgress /></LoadRootDiv>}


      <Grid container spacing={2}>

        {!loading && !error && milestones.length === 0 && <Grid item xs={6} sm={4} md={3}><Typography variant="body2" style={{ marginTop: theme.spacing(2) }}><em>{translate('retro.milestoneList.pleaseAddMilestone')}</em></Typography></Grid>}

        {typeof milestones !== 'undefined' && milestones.map(milestone => (
          <MilestoneListItem key={milestone.key} milestoneKey={milestone.key} roomId={roomId} data={milestone.val()} onEditClicked={handleEditClicked} />
        ))}

        <Grid item xs={6} sm={4} md={3} sx={{ alignContent: "center" }}>
          <Button variant="outlined" sx={{ color: theme.palette.primary.veryLight, borderColor: theme.palette.primary.veryLight,':hover': {backgroundColor: theme.palette.primary.hoverBackground} }} onClick={handleClickOpen}><AddIcon />{translate('retro.milestoneList.addMilestoneButtonTitle')}</Button>
        </Grid>
        <MilestoneDialog open={dialogOpen} handleClose={handleClose} roomId={roomId} modifyKey={modifyKey} modifyData={modifyData} />
      </Grid>

    </>

  );
}


MilestoneList.propTypes = {
  user: PropTypes.object,

};

export default MilestoneList;
